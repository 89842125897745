import { Navbar } from "components/Navbar/Navbar"
import * as React from "react"
import "./baseApp.scss"

type BaseAppProps = {
  children: React.ReactNode
  className?: string
  ref?: React.MutableRefObject<HTMLDivElement | null>
}

export const BaseApp = ({ children, className, ref }: BaseAppProps) => {
  return (
    <div className={`base-app ${className}`} ref={ref}>
      <div className="base-app-top ">
        <Navbar />
      </div>
      <div className="base-app-content">{children}</div>
    </div>
  )
}
