import { InputDeviceInfo } from "@actionatics/rtstt-shared/build/dataTypes/InputDeviceInfo"
import { createReducer } from "@reduxjs/toolkit"
import { DEVICES_SELECT, DEVICES_SUCCESS } from "../actions"

interface State {
  devices: InputDeviceInfo[]
  selected: number
}

const initialState: State = {
  devices: [],
  selected: -1,
}

const preferred = [/soundflower/i, /blackhole/i]

export default createReducer(initialState, (builder) => {
  builder.addCase(DEVICES_SUCCESS, (state, { payload }) => {
    state.devices = payload.sort((a, b) => {
      const aIndex = preferred.findIndex((pref) => a.label.match(pref) != null)
      const bIndex = preferred.findIndex((pref) => b.label.match(pref) != null)
      if (bIndex === -1) {
        return -1
      }

      if (aIndex === -1) {
        return 1
      }

      return aIndex - bIndex
    })

    if (state.selected === -1) {
      state.selected = 0
    }
  })

  builder.addCase(DEVICES_SELECT, (state, { payload }) => {
    state.selected = payload
  })
})
