import { Accordion } from "react-bootstrap"

export const FAQ = () => {
  return (
    <div>
      <h2>Preguntas Frequentes</h2>

      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <h3>¿ Para qué sirve ?</h3>
          </Accordion.Header>
          <Accordion.Body>
            My Interpreter, es una herramienta de uso personal que toma notas en
            tiempo real de conversaciones y te permite volver a escuchar
            secciones. Al tener la transcripción en texto, te permite buscar
            rápidamente partes que no hayas que no hayas entendido y escuchar
            solo ese segmento.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            <h3>
              ¿ Pueden el resto de los participantes de la videollamada saber
              que estoy usando esta herramienta?
            </h3>
          </Accordion.Header>
          <Accordion.Body>
            <h3>
              No pueden saberlo. Esta herramienta esta pensada para uso
              personal, el objetivo es ayudarte a tener una mejor comprensión.
            </h3>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>
            <h3>¿ Guardan mis conversaciones en sus servidores ?</h3>
          </Accordion.Header>
          <Accordion.Body>
            No, por cuestiones de privacidad, el audio de la aplicación solo
            permanece en tu dispositivo. Si cierras o refrescas la página, la
            conversación se perderá por completo.
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  )
}
