import { __ } from "i18n"
import { RootState } from "state/store"
import { StatusImage } from "./StatusImage"

const ENTRY_POINT = "/status"

type StatusProps = {
  handleSignIn: () => any
  handleSignUp: () => any
  extensionData: RootState["extension"]
}

export const LoggedIn = ({ logout }: { logout: () => any }) => {
  return (
    <div className="d-flex">
      <a className="btn btn-primary btn-lg" href={ENTRY_POINT}>
        Probar
      </a>
      <button
        className="btn  btn-outline-primary btn-lg py-0"
        onClick={() => logout()}
      >
        Logout
      </button>
    </div>
  )
}

/**
 * Shows the current status of the extension
 */
export const Status = ({
  extensionData,
  handleSignIn,
  handleSignUp,
}: StatusProps) => {
  if (!extensionData.isAuth) {
    return (
      <div className="container" style={{ padding: "10rem" }}>
        <div className="row">
          <div className="text-center col-12 h2">
            {__("NOT_LOGGED_IN_MESSAGE")}
          </div>
          <div className="col-12 text-center">
            <StatusImage
              recordingState={extensionData.recordingState}
              isAuth={extensionData.isAuth}
            />
          </div>
          <div className="text-center col-6 my-4">
            {__("NOT_LOGGED_IN_MESSAGE_IF_REGISTERED")}
            <div>
              <button className="btn btn-primary btn-lg" onClick={handleSignIn}>
                {__("SIGN_IN")}
              </button>
            </div>
          </div>
          <div className="text-center col-6 my-4">
            {__("NOT_LOGGED_IN_MESSAGE_IF_NOT_REGISTERED")}
            <div>
              <button
                className="btn btn-lg btn-outline-primary"
                onClick={handleSignUp}
              >
                {__("SIGN_UP")}
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  if (extensionData.isAuth) {
    return (
      <div className="container" style={{ padding: "10rem" }}>
        <div className="row">
          <div className="text-center col-12 h2 mx-6 px-6">
            {__("YOU_ARE_READY")}
          </div>
          <div className="text-center col-12 my-4">
            <div className="col-12 text-center">
              <StatusImage
                recordingState={extensionData.recordingState}
                isAuth={extensionData.isAuth}
              />
            </div>
            <div>
              <span className="badge text-primary">
                {__("MEANING_ITS_TRANSCRIBING")}
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return null
}
