import { ApiClient } from "api/ApiClient"
import axios from "axios"
import { Dispatch } from "redux"
import {
  UI_SESSION_CREATE_ERROR,
  UI_SESSION_CREATE_INIT,
  UI_SESSION_CREATE_SUCCESS,
} from "state/actions/ui"
import { RootState } from "state/store"
import { debounceByProp } from "utils/debouncers"
import { TRANSLATE_API } from "../../config"
import { UI_TRANSLATED_TEXT_ADD, UI_TRANSLATED_TEXT_INIT } from "../actions"

import { Payload } from "../types"

export const uiListeners = (dispatch: Dispatch) => ({
  [UI_TRANSLATED_TEXT_INIT.type]: debounceByProp(
    "id",
    "isFinal",
    async (
      { id, textToTranslate }: Payload<typeof UI_TRANSLATED_TEXT_INIT>,
      _,
      signal
    ) => {
      try {
        const reponse = await axios.post<{ translatedText: string }>(
          TRANSLATE_API,
          {
            q: textToTranslate,
            source: "en",
            target: "es",
          },
          { signal }
        )
        if (reponse.data.translatedText) {
          dispatch(
            UI_TRANSLATED_TEXT_ADD({ [id]: reponse.data.translatedText })
          )
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error("Could not translate text, api failed")
          return
        }
      }
    }
  ),
  [UI_SESSION_CREATE_INIT.type]: async (
    { meetingDetails, isCustomClient }: Payload<typeof UI_SESSION_CREATE_INIT>,
    store: RootState
  ) => {
    const apiClient = new ApiClient(store.user.token)
    try {
      const body = await apiClient.newSession(meetingDetails, isCustomClient)
      if (body.recordingId) {
        dispatch(UI_SESSION_CREATE_SUCCESS(body.recordingId))
      }
    } catch (error) {
      console.error(error)
      dispatch(UI_SESSION_CREATE_ERROR())
    }
  },
})
