import { configureStore } from "@reduxjs/toolkit"
import { listenersMiddleware } from "./listenersMiddleware"
import reducer from "./reducers"

export const store = configureStore({
  devTools: true,
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(listenersMiddleware),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
