import { BOT_NAME } from "config"

export class EnI18N {
  static INSERT_MEETING_DETAILS_LABEL: string =
    "Paste a meeting URL (zoom, google meet, jitsi)"
  static INSERT_MEETING_DETAILS_PLACEHOLDER: string =
    "e.g: https://meet.jit.si/xxxxxxxxxxxxx  https://meet.google.com/xxxxxxx"
  static CREATE_A_NEW_MEETING_DESCRIPTION: string =
    "Create a Jitsi meeting right away and share the link"
  static CREATE_A_NEW_MEETING_BTN: string = "Create Jitsi meeting"
  static JOIN = "Ask Interpreter to Join"
  static SETUP_NEW_MEETING: string = "New meeting"
  static WHAT_WILL_HAPPEN: string = "What will happen?"
  static WW_P1 =
    "Our bot will try to join your meeting, all the attendees will see it and will know they are being recorded by it."
  static WW_P2 = "The bot will send you real time transcripts and translations."
  static "Leave" = "Leave"
  static MINUTES = "minute(s)"
  static HOURS = "hour(s)"
  static SECONDS = "second(s)"
  static UNKNOWN_PERSON = ""
  static TRANSCRIPT_FROM = "Transcription from: "
  static BOT_NAME = BOT_NAME

  // Bot states
  static INACTIVE = "Inactive"
  static CLOSED = "Closed"
  static JOINING = "Joining to meeting"
  static CONNECTED = "CONNECTED"
  static JOINING_ERROR = "Error when trying to join the meeting"

  // stream states, we show them when bot state is connected
  static NOT_STARTED = "Connected - Starting streaming..."
  static WAITING_FOR_AUDIO = "Connected - Waiting for audio activity..."
  static STREAMING = "Connected - Streaming"
  static ERROR = "Error during streaming"

  // old app
  static RECORD_FROM_PC = "Or record from computer"
  static NOT_LOGGED_IN_MESSAGE =
    "Necesitas  ingresar como usario para poder usar la extension."
  static NOT_LOGGED_IN_MESSAGE_IF_REGISTERED = "Si ya tienes un usuario"
  static NOT_LOGGED_IN_MESSAGE_IF_NOT_REGISTERED =
    "If you are not registered yet"
  static SIGN_IN = "Sign in"
  static SIGN_UP = "Sign up"
  static YOU_ARE_READY =
    "You are ready! Go back to the tab in which you want to use MyInterpreter. You'll see the status icon in green:"
  static MEANING_ITS_TRANSCRIBING = "This means it's transcribing"
}
