import {
  BotState,
  StreamState,
} from "@actionatics/rtstt-shared/build/dataTypes/BotState"
import { DOWNLOAD_URL } from "config"
import { useEffect, useState } from "react"

// every 5 seconds when streaming
const INTERVAL = 5000

const makeUrl = (sessionId: string) => {
  return DOWNLOAD_URL + sessionId + ".webm?" + Date.now()
}

export const useRealTimeVideo = (
  sessionId: string,
  stateBot: BotState,
  stateStream: StreamState
) => {
  const [url, setUrl] = useState("")

  useEffect(() => {
    if (stateBot === BotState.CONNECTED && StreamState.STREAMING) {
      setTimeout(() => setUrl(makeUrl(sessionId)), INTERVAL)
    } else if (stateBot === BotState.CLOSED) {
      setUrl(makeUrl(sessionId))
    }
  }, [stateBot, stateStream, sessionId])

  return url
}
