import { Auth0Provider } from "@auth0/auth0-react"
import { AuthExtension } from "components/AuthExtension/AuthExtension"
import { Provider } from "react-redux"
import { RouterProvider } from "react-router-dom"
import router from "./pages/routes"
import { store } from "./state/store"

const App = () => (
  <Provider store={store}>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN as string}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE as string}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID as string}
      redirectUri={`${window.location.origin}/status`}
      cacheLocation="localstorage"
    >
      <AuthExtension />
      <RouterProvider router={router} />
    </Auth0Provider>
  </Provider>
)

export default App
