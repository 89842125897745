import { API_URL } from "config"
import { SessionUsageResponse, ZoomGetSignatureRequest } from "./ResponsesTypes"

export class ApiClient {
  bearerToken: string
  apiKey: string

  constructor(bearerToken: string, apiKey: string = "") {
    this.bearerToken = bearerToken
    this.apiKey = apiKey
  }

  async getAssemblyToken() {
    const response = await fetch(`${API_URL}/tokens/assemblyai`, {
      headers: {
        Authorization: `Bearer ${this.bearerToken}`,
      },
    })
    const { token } = await response.json()
    return token
  }

  async getMeetingDetails(sessionId: string) {
    const response = await fetch(
      `${API_URL}/meetingDetails/` + sessionId + "?apiKey=" + this.apiKey,
      {}
    )
    const { meetingDetails } = await response.json()
    return meetingDetails as string
  }

  async getZoomSignature(payload: ZoomGetSignatureRequest) {
    const response = await fetch(
      `${API_URL}/zoomSignature?apiKey=${this.apiKey}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${this.bearerToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    )

    return (await response.json()) as { signature: string; sdkKey: string }
  }

  async usage(
    id: string,
    messagesCount: number,
    tokensCount: number,
    timeLengthInSecs: number
  ) {
    const response = await fetch(`${API_URL}/session-usage`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${this.bearerToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        recordingId: id,
        timeLengthInSecs,
        messagesCount,
        tokensCount,
      }),
    })

    return (await response.json()) as SessionUsageResponse
  }

  async newSession(meetingDetails: string, isCustomClient: boolean) {
    const response = await fetch(`${API_URL}/session-new`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${this.bearerToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        meetingDetails,
        isCustomClient,
      }),
    })

    if (!response.ok) {
      throw new Error("Request failed with status " + response.status)
    }

    return (await response.json()) as SessionUsageResponse
  }
}
