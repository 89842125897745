import { EnI18N } from "./en"

export class EsI18N extends EnI18N {
  // extension
  static NOT_LOGGED_IN_MESSAGE =
    "Necesitas ingresar como usario para poder usar la extension."
  static NOT_LOGGED_IN_MESSAGE_IF_REGISTERED = "Si ya tienes un usuario"
  static NOT_LOGGED_IN_MESSAGE_IF_NOT_REGISTERED = "Si aún no te registraste"
  static SIGN_IN = "Login"
  static SIGN_UP = "Registrarse"

  static YOU_ARE_READY =
    "Ya estás listo! Vuelve a la página donde quieres usar MyInterpreter. Verás el ícono de estado en verde:"
  static MEANING_ITS_TRANSCRIBING = "Esto significa que está transcribiendo"
}
