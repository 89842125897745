import { useEffect, useRef, useState } from "react"
type MediaPlayerProps = {
  src: string
  onChangeCursor?: (timeinSecs: number) => any
}

// if you update this, you need to update $height-top-video in src/pages/transcript/transcriptPage.scss
const height = 200

export const MediaPlayer = ({ src, onChangeCursor }: MediaPlayerProps) => {
  const videoRef = useRef<HTMLVideoElement | null>(null)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentTime, setCurrentTime] = useState(0)

  //   const playVideo = () => {
  //     videoRef.current?.play()
  //   }

  //   const pauseVideo = () => {
  //     videoRef.current?.pause()
  //   }

  const updateCurrentTime = () => {
    setCurrentTime(videoRef.current?.currentTime || 0)
  }

  useEffect(() => {
    onChangeCursor?.(currentTime)
  }, [currentTime, onChangeCursor])

  return (
    <div>
      <video
        ref={videoRef}
        controls
        onTimeUpdate={updateCurrentTime}
        height={height}
      >
        <source src={src} type="video/webm" />
        Your browser does not support the video tag.
      </video>
      {/* <div>Current Time: {currentTime.toFixed(2)} seconds</div>
      <button onClick={playVideo}>Play</button>
      <button onClick={pauseVideo}>Pause</button> */}
    </div>
  )
}
