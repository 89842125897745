import { Code } from "components/Code/Code"
import { AiFillMacCommand } from "react-icons/ai"
import { FaMicrophoneAlt } from "react-icons/fa"
import { TutorialItem } from "../../TutorialItem/TutorialItem"

const VBDownloadLink =
  "https://download.vb-audio.com/Download_MAC/VBCable_MACDriver_Pack108.zip"

export const InitialHelpMAC = ({ prefixID = "" }: { prefixID: string }) => {
  return (
    <div>
      <TutorialItem
        id={`${prefixID}-1`}
        n={1}
        urlNext={`#${prefixID}-2`}
        title="Instala VB-CABLE: Descarga"
        imageUrl="/images/Download-VS-osx.png"
        imageClass="img-75"
        dscTop={
          <>
            <div>
              VB-Cable es una aplicación gratuita para macOS. Puedes descargarla
              mediante de alguna de estas 3 opciones:
              <ul>
                <li>
                  Enlace directo: <a href={VBDownloadLink}>Descarga VB-Cable</a>
                </li>
              </ul>
              <li>
                O desde su página oficial:
                <a
                  href="https://vb-audio.com/Cable/index.htm"
                  target="_blank"
                  rel="noreferrer"
                >
                  VB-Cable Virtual Audio
                </a>
              </li>
            </div>
          </>
        }
        dscBottom={
          <ul>
            <li>
              O mediante <code>brew</code>. En una terminal, ejecuta el
              siguiente comando:
              <div>
                <Code>brew install --cask vb-cable</Code>
              </div>
              haciéndolo de esta manera, puedes obviar los siguientes pasos e ir
              directamente al punto
              <a className="tutorial-item-number tiny" href="#tutorial-item-4">
                4
              </a>
            </li>
          </ul>
        }
      />
      <TutorialItem
        id={`${prefixID}-2`}
        n={2}
        urlNext={`#${prefixID}-3`}
        title="Instala VB-CABLE: Descomprime y ejecuta el instalador"
        imageUrl="/images/execute-this-file.png"
        dscTop="Descomprime y ejecuta este archivo"
      />
      <TutorialItem
        id={`${prefixID}-3`}
        n={3}
        urlNext={`#${prefixID}-4`}
        title="Instala VB-CABLE: Haz doble click para comenzar la instalación"
        imageUrl="/images/VS-Install.png"
        dscBottom="Sigue las instrucciones del instalador."
      />
      {/* <TutorialItem
        id={`${prefixID}-3.1`}
        urlNext={`#${prefixID}-4`}
        imageUrl="/images/vs-device-2.png"
        dscTop={
          <>
            Una vez instalado, si haces click en el ícono Sonido{" "}
            <FaVolumeUp fontSize={20} /> de tu Mac, verás 'VB-Cable' como una de
            las opciones disponibles.
          </>
        }
        dscBottom={
          <>
            No hace falta que lo selecciones, solo necesitamos saber que está
            presente{" "}
          </>
        }
      /> */}
      <TutorialItem
        id={`${prefixID}-4`}
        n={4}
        urlNext={`#${prefixID}-5`}
        title="A continuación, crearemos un dispositivo de salida múltiple"
        imageUrl="/images/osx/es/osx-launch-audio-midi.png"
        dscTop={
          <>
            Abre la Configuración de Audio Midi: <br /> Presiona simultáneamente{" "}
            <AiFillMacCommand fontSize={30} />+<i>Barra espaciadora</i> y tipea
            "Audio Midi"
          </>
        }
      />
      <TutorialItem
        id={`${prefixID}-5`}
        n={5}
        urlNext={`#${prefixID}-6`}
        title="Creando un dispositivo de salida múltiple"
        imageUrl="/images/osx/es/osx-midi-adding-multi.png"
        dscTop={
          <>
            Crea un dispositivo de salida múltiple. Para ello, haz clic en el
            símbolo
            <code className="tutorial-item-symbol"> + </code> que se encuentra
            abajo a la derecha (como en la figura debajo) y luego en{" "}
            <code>Crear dispositivo de salida múltiple</code>.
          </>
        }
      />
      <TutorialItem
        id={`${prefixID}-6`}
        n={6}
        urlNext={`#${prefixID}-7`}
        title="Creando un dispositivo de salida múltiple: Selecciona los dispositivos. Asegurate de seleccionar `VB-Cable` y tus Auriculares."
        imageUrl="/images/osx/es/osx-midi-checking-vb-cable.png"
        dscTop="Selecciona VB-Cable y tus auriculares:"
        dscBottom="Si lo deseas, puedes renombrarlo haciendo doble clic donde indica la figura:"
        imageUrl2="/images/osx/es/osx-midi-rename.png"
      />
      <TutorialItem
        id={`${prefixID}-7`}
        n={7}
        title="Selecciona el dispositivo de salida en la videollamada"
        imageUrl="/images/zoom-select-output.png"
        imageClass="img-50"
        dscTop={
          <>
            En la aplicación que utilizas para tus videollamadas (Zoom,
            Microsoft Teams, etc) selecciona como salida, el dispostivo que
            acabas de crear
            <br />
          </>
        }
        dscBottom={
          <>
            <b>Y ya estas listo!!!</b>
            <ul>
              <li>
                Crea una nueva transcripción entrando a:
                <a href="https://app.myinterpreter.co/recorder">
                  https://app.myinterpreter.co/recorder
                </a>
              </li>
              <li>
                Asegurate que <code>VB-Cable</code> esté seleccionado como
                dispositivo de entrada.
              </li>
              <li>
                Y haz click en{" "}
                <FaMicrophoneAlt
                  size={20}
                  className="start-button-ref"
                ></FaMicrophoneAlt>{" "}
                para comenzar la grabacion:
              </li>
            </ul>
          </>
        }
        imageUrl2="/images/start-recording-os.png"
        imageClass2="img-50"
        dscBottom2="Esperamos que disfrutes de la experiencia!!!"
      />
    </div>
  )
}
