import { useAuth0 } from "@auth0/auth0-react"
import { BaseApp } from "components/App/BaseApp/BaseApp"
import { Status } from "components/Status/Status"
import { useCallback } from "react"
import { useSelector } from "react-redux"
import { RootState } from "state/store"

const StatusPage = () => {
  const { loginWithRedirect } = useAuth0()

  const extension = useSelector((state: RootState) => state.extension)

  const handleSignIn = useCallback(
    () => loginWithRedirect(),
    [loginWithRedirect]
  )
  const handleSignUp = useCallback(() => {
    loginWithRedirect({
      screen_hint: "signup",
    })
  }, [loginWithRedirect])

  return (
    <BaseApp>
      <Status
        handleSignIn={handleSignIn}
        handleSignUp={handleSignUp}
        extensionData={extension}
      />
    </BaseApp>
  )
}

export default StatusPage
