import { DevicesSelector } from "@actionatics/rtstt-shared/build/components/DevicesSelector"
import { useAuth0 } from "@auth0/auth0-react"
import { AudioMessagesContainer } from "components/AudioMessagesContainer/AudioMessagesContainer"
import { HelpOverlay } from "components/Help/HelpOverlay/HelpOverlay"
import { InitialHelp } from "components/Help/InitialHelp"
import { LeftMenu } from "components/LeftMenu"
import { useStickyScroll } from "hooks/useStickyScroll"
import { useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  AUDIO_PLAY_START,
  AUDIO_PLAY_STOP,
  AUDIO_RECORD_END,
  AUDIO_RECORD_START,
  DEVICES_INIT,
  DEVICES_SELECT,
  USER_SET_DATA,
  USER_SET_TOKEN,
} from "state/actions"
import { UI_SESSION_CREATE_INIT } from "state/actions/ui"
import { RootState } from "state/store"
import "styles/layout.scss"
import "./recorder.scss"

function Recorder() {
  const dispatch = useDispatch()
  const devices = useSelector((state: RootState) => state.devices)
  const isRecording = useSelector((state: RootState) => state.audio.isRecording)
  const isPlaying = useSelector((state: RootState) => state.audio.isPlaying)
  const events = useSelector((state: RootState) => state.ui.events)
  const cursor = useSelector((state: RootState) => state.audio.cursor)
  const sessionId = useSelector((state: RootState) => state.ui.id)

  const translatedText = useSelector(
    (state: RootState) => state.ui.translatedTexts
  )

  const { getAccessTokenSilently, user, isAuthenticated, isLoading } =
    useAuth0()

  useEffect(() => {
    ;(async () => {
      const token = await getAccessTokenSilently()
      dispatch(USER_SET_TOKEN(token as string))
    })()
  }, [getAccessTokenSilently, dispatch])

  useEffect(() => {
    if (!isAuthenticated && isLoading === false) {
      window.location.replace("/")
    }
  }, [isAuthenticated, isLoading])

  useEffect(() => {
    if (isAuthenticated && !sessionId) {
      dispatch(
        UI_SESSION_CREATE_INIT({ meetingDetails: "", isCustomClient: false })
      )
    }
  }, [isAuthenticated, sessionId, dispatch])

  useEffect(() => {
    dispatch(USER_SET_DATA(user || null))
  }, [user, dispatch])

  useEffect(() => {
    dispatch(DEVICES_INIT())
  }, [dispatch])

  const onRecordStart = () => {
    if (devices.devices[devices.selected]) {
      dispatch(AUDIO_RECORD_START(devices.devices[devices.selected]))
    }
  }

  const onRecordStop = () => {
    dispatch(AUDIO_RECORD_END())
  }

  const onPlayStart = () => {
    dispatch(AUDIO_PLAY_START())
  }

  const onPlayStartMessage = (range: { from: number; to?: number }) => {
    dispatch(AUDIO_PLAY_START(range))
  }

  const onPlayStop = () => {
    dispatch(AUDIO_PLAY_STOP())
  }

  const onSelectDevice = (event: any) => {
    dispatch(DEVICES_SELECT(event.target.value))
  }

  const [scrollableDivRef, isScrollAtBottom] = useStickyScroll([events])
  const goToBottomScroll = useCallback(() => {
    if (scrollableDivRef.current) {
      const elm = scrollableDivRef.current
      elm.scrollTop = elm.scrollHeight - elm.offsetHeight
    }
  }, [scrollableDivRef])

  return (
    <div className="recorder-main">
      <HelpOverlay />
      <div className="block-left-bar d-flex align-items-end vh-100 justify-content-between mt-2">
        <LeftMenu
          isRecording={isRecording}
          isPlaying={isPlaying}
          onRecordStop={onRecordStop}
          onRecordStart={onRecordStart}
          onPlayStart={onPlayStart}
          onPlayStop={onPlayStop}
        />
      </div>
      <div className="block-top-bar p-3">
        <label className="font-weight-bold">
          Selecciona tu disposivo de entrada:
        </label>
        <br />
        <DevicesSelector
          values={devices.devices}
          selected={devices.selected}
          onChange={onSelectDevice}
        />
      </div>
      <div className="block-text-container" ref={scrollableDivRef}>
        {events.length === 0 ? (
          <InitialHelp enableOnboardingButon />
        ) : (
          <AudioMessagesContainer
            events={events}
            translatedText={translatedText}
            onPlayStart={onPlayStartMessage}
            onPlayStop={onPlayStop}
            isRecording={isRecording}
            isPlaying={isPlaying}
            cursor={cursor}
            isScrollAtBottom={isScrollAtBottom}
            goToBottomScroll={goToBottomScroll}
          />
        )}
      </div>
    </div>
  )
}

export default Recorder
