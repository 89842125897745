type StatusImageProps = { recordingState: string; isAuth: boolean }

export const MAPPING_COLORS: any = {
  idle: "gray",
  recording: "#00FF00",
  "recording-starting": "orange",
  "recording-stopping": "yellow",
}

export const StatusImage = ({ recordingState }: StatusImageProps) => {
  const color: any =
    MAPPING_COLORS[recordingState] !== undefined
      ? MAPPING_COLORS[recordingState]
      : MAPPING_COLORS["idle"]

  return (
    <svg
      width="89"
      height="93"
      viewBox="0 0 89 93"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M44.4676 86.7792C67.8357 86.7792 86.7794 67.9734 86.7794 44.7753C86.7794 21.5772 67.8357 2.77148 44.4676 2.77148C21.0994 2.77148 2.15576 21.5772 2.15576 44.7753C2.15576 67.9734 21.0994 86.7792 44.4676 86.7792Z"
        fill="black"
      />
      <path
        d="M41.6532 13.8418C41.1784 13.8423 40.7135 13.8564 40.2723 13.8834C39.8274 13.9117 39.3927 13.9526 38.9816 14.005C38.3493 14.0906 37.7782 14.1906 37.2087 14.3252C37.0223 14.3695 36.8392 14.4165 36.6596 14.4661C31.2711 15.9556 28.9305 19.8382 27.9187 23.1225C27.8512 23.3415 27.7895 23.5579 27.7333 23.7706C27.6772 23.9834 27.6264 24.1925 27.5805 24.3973C27.0759 26.6498 27.1626 28.366 27.1626 28.366V32.9093C27.1626 32.9093 25.9249 32.3768 24.6236 32.6819C24.5369 32.7023 24.4498 32.7264 24.3629 32.7546C24.2759 32.7828 24.1889 32.8152 24.1024 32.852C24.0159 32.8889 23.9299 32.9302 23.8446 32.9765C22.6503 33.6251 21.6053 35.242 21.6648 38.9409C21.7243 42.6398 23.2845 44.3767 25.0208 45.1602C25.1448 45.2162 25.2696 45.2673 25.395 45.3139C25.5203 45.3605 25.6459 45.4026 25.7716 45.4405C25.8973 45.4785 26.0229 45.5124 26.148 45.5425C28.024 45.9939 29.7765 45.5992 29.7765 45.5992L29.9566 28.131C29.9566 28.131 29.9415 27.9459 29.9391 27.6229C29.938 27.4614 29.9399 27.2653 29.9487 27.0406C29.9796 26.2542 30.0933 25.1166 30.441 23.8792C30.4907 23.7024 30.545 23.5236 30.6047 23.3435C30.6644 23.1634 30.7293 22.982 30.7999 22.7999C31.9991 19.7058 34.8334 16.4459 41.4692 16.6184C42.38 16.6421 44.4622 16.5695 44.4622 16.5695L44.4677 13.9528C44.4677 13.9528 43.9999 13.9249 43.4143 13.8972C42.8287 13.8694 42.125 13.8418 41.6532 13.8418ZM44.4677 13.9528L43.4537 16.5695C43.4537 16.5695 46.5551 16.6421 47.4659 16.6184C59.9568 16.2936 58.9784 28.131 58.9784 28.131L59.1586 45.5992C59.1586 45.5992 67.1343 47.3956 67.2703 38.9409C67.4064 30.4862 61.7725 32.9093 61.7725 32.9093V28.3659C61.7725 28.3659 62.5059 13.8423 47.2819 13.8418C46.3382 13.8418 44.4677 13.9528 44.4677 13.9528Z"
        fill="white"
      />
      <path
        d="M59.1393 46.313L60.0333 46.313C60.0333 46.313 59.3165 59.9689 57.1409 63.0462C54.9653 66.1234 46.9798 64.7766 46.9798 64.7766V62.8574C46.9798 62.8574 54.4322 65.0418 56.6873 61.5286C58.9424 58.0154 59.1393 46.313 59.1393 46.313Z"
        fill="white"
      />
      <path
        d="M44.6988 66.0387C47.3254 66.0387 49.4546 64.906 49.4546 63.5088C49.4546 62.1116 47.3254 60.979 44.6988 60.979C42.0722 60.979 39.943 62.1116 39.943 63.5088C39.943 64.906 42.0722 66.0387 44.6988 66.0387Z"
        fill="white"
      />
      <path
        d="M38.763 52.3809C38.763 52.3809 39.4877 53.8386 45.4611 53.8509C51.4345 53.8633 52.0427 52.4821 52.0427 52.4821C52.0427 52.4821 50.9982 57.5676 45.3451 57.7462C39.6921 57.9248 38.763 52.3809 38.763 52.3809Z"
        fill="white"
      />
      <path
        d="M33.7281 60.6504C33.7281 60.6504 42.2284 75.9243 50.1632 67.3459C42.963 69.17 39.9223 68.6809 33.7281 60.6504Z"
        fill="white"
      />
      <path
        d="M21.2957 57.3397L27.6434 75.0916C27.6434 75.0916 28.4213 81.0018 21.2229 70.7727C14.0245 60.5437 20.6364 53.8852 21.3418 35.6823C22.0473 17.4794 44.2741 9.09619 44.2741 9.09619C44.2741 9.09619 20.2948 10.6538 20.1528 34.4676C20.0108 58.2814 12.7572 64.9425 17.002 72.3248C21.2469 79.7071 32.6475 81.3084 30.6543 70.4241C28.6611 59.5398 21.2957 57.3397 21.2957 57.3397ZM67.6394 57.3397L61.2917 75.0916C61.2917 75.0916 60.5137 81.0018 67.7122 70.7727C74.9106 60.5437 68.2987 53.8852 67.5933 35.6823C66.8878 17.4794 44.661 9.09619 44.661 9.09619C44.661 9.09619 68.6403 10.6538 68.7823 34.4676C68.9243 58.2814 76.1779 64.9425 71.9331 72.3248C67.6882 79.7071 56.2876 81.3084 58.2808 70.4241C60.274 59.5398 67.6394 57.3397 67.6394 57.3397Z"
        fill="white"
      />
      <path
        d="M42.2791 47.7773C42.2791 47.7773 44.2319 49.2833 45.1165 49.196C46.001 49.1087 47.6934 47.7773 47.6934 47.7773C47.6934 47.7773 46.5567 48.5608 45.0492 48.5328C43.5417 48.5049 42.2791 47.7773 42.2791 47.7773Z"
        fill="white"
      />
      <path
        d="M39.7805 24.1494C38.8291 25.5346 37.6399 26.7435 36.45 27.9223C34.7845 29.5105 33.0626 31.0417 31.2261 32.4295C29.9143 33.3538 28.592 34.264 27.2396 35.1282L28.4983 35.9352C29.8227 35.038 31.1365 34.1256 32.4382 33.1958C34.2814 31.8138 35.9565 30.2271 37.6299 28.647C38.8453 27.4662 40.0735 26.2827 41.1152 24.9415L39.7805 24.1494Z"
        fill="white"
      />
      <path
        d="M36.5201 20.9897C37.8073 22.413 39.1926 23.801 40.7876 24.8836C41.3608 25.2727 41.9722 25.6023 42.5645 25.9616C46.026 28.003 49.6487 29.7561 53.3633 31.2831C54.1969 31.7385 55.1557 31.8603 56.0279 32.1978C56.6112 32.4984 57.244 32.6409 57.8777 32.8015C58.8031 33.0326 59.7403 33.2101 60.6843 33.3444C60.9671 33.3808 61.2513 33.3902 61.5358 33.3941L59.6358 32.014C59.3671 32.0187 59.098 31.9869 58.8312 31.9596C57.9049 31.8406 56.9869 31.6625 56.0833 31.4255C55.4372 31.2477 54.8131 31.036 54.1969 30.7744C53.3297 30.5249 52.4546 30.3018 51.631 29.9198C50.4751 29.4597 49.3278 28.9838 48.1987 28.4601C47.0035 27.9058 45.8312 27.3145 44.6507 26.7295C44.5908 26.7016 44.521 26.7044 44.4561 26.6918C43.8819 26.3483 43.2885 26.0349 42.7335 25.6612C41.2012 24.6296 39.862 23.3017 38.658 21.9111L36.5201 20.9897Z"
        fill="white"
        stroke="white"
        strokeWidth="2"
      />
      <path
        d="M35.7217 39.4103C35.7217 39.4103 36.3333 38.974 37.2904 38.7911C38.2475 38.6082 38.9611 38.7118 39.891 39.0387C40.8209 39.3657 41.3771 39.6167 41.3771 39.6167C41.3771 39.6167 40.5269 38.616 39.6021 38.2544C38.6772 37.8928 38.2311 37.8798 36.9601 38.1306C35.6891 38.3813 35.7217 39.4103 35.7217 39.4103ZM53.2134 39.4103C53.2134 39.4103 52.6018 38.974 51.6447 38.7911C50.6876 38.6082 49.974 38.7118 49.044 39.0387C48.1141 39.3657 47.558 39.6167 47.558 39.6167C47.558 39.6167 48.4081 38.616 49.333 38.2544C50.2579 37.8928 50.704 37.8798 51.975 38.1306C53.246 38.3813 53.2134 39.4103 53.2134 39.4103Z"
        fill="white"
      />
      <path
        d="M39.0891 38.6104C38.3564 38.6104 37.7626 39.145 37.7626 39.8044C37.7626 40.4638 38.3564 40.9984 39.0891 40.9985C39.8219 40.9986 40.416 40.4639 40.4159 39.8044C40.416 39.1449 39.8219 38.6102 39.0891 38.6104ZM49.8461 38.6104C50.5787 38.6104 51.1726 39.145 51.1725 39.8044C51.1726 40.4638 50.5787 40.9984 49.8461 40.9985C49.1133 40.9986 48.5192 40.4639 48.5192 39.8044C48.5192 39.1449 49.1133 38.6102 49.8461 38.6104Z"
        fill="white"
      />
      <path
        d="M46.5947 35.4409C46.5947 35.4409 49.3324 35.1858 50.6198 35.2283C51.9072 35.2708 54.3192 35.6958 54.3192 35.6958C54.3192 35.6958 54.5591 35.1303 53.4795 34.6762C52.4 34.2221 48.7863 34.335 47.6582 34.6762C46.5301 35.0174 46.5947 35.4409 46.5947 35.4409ZM42.3404 35.4409C42.3404 35.4409 39.6027 35.1858 38.3153 35.2283C37.0278 35.2708 34.6159 35.6958 34.6159 35.6958C34.6159 35.6958 34.376 35.1303 35.4555 34.6762C36.5351 34.2221 40.1488 34.335 41.2769 34.6762C42.405 35.0174 42.3404 35.4409 42.3404 35.4409Z"
        fill="white"
      />
      <path
        d="M42.1128 35.4477C42.1128 35.4477 39.375 35.1927 38.0876 35.2351C36.8001 35.2776 34.3882 35.7026 34.3882 35.7026C34.3882 35.7026 34.1483 35.1371 35.2278 34.683C36.3074 34.2289 39.9211 34.3418 41.0492 34.683C42.1774 35.0242 42.1128 35.4477 42.1128 35.4477ZM46.8224 35.4477C46.8224 35.4477 49.5601 35.1927 50.8475 35.2351C52.135 35.2776 54.5469 35.7026 54.5469 35.7026C54.5469 35.7026 54.7868 35.1371 53.7073 34.683C52.6278 34.2289 49.014 34.3418 47.8859 34.683C46.7577 35.0242 46.8224 35.4477 46.8224 35.4477Z"
        fill="white"
      />
      <circle cx="71" cy="77" r="16" fill={color} />
    </svg>
  )
}
