import UiText, {
  ActionType,
  UiTextBot,
  UiTextTranscription,
} from "@actionatics/rtstt-shared/build/dataTypes/UiText"
import { BotMessageBox } from "components/BotMessageBox/BotMessageBox"
import { Toast } from "react-bootstrap"
import { TranscriptDisplayer } from "../TranscriptDisplayer/TranscriptDisplayer"
import "./AudioMessagesContainer.scss"

type AudioMessagesContainerProps = {
  events: UiText[]
  translatedText: {
    [id: string]: string
  }
  onPlayStart: (range: { from: number; to?: number }) => void
  onPlayStop: () => void
  isPlaying: boolean
  isRecording: boolean
  cursor: number
  isScrollAtBottom: boolean
  goToBottomScroll: () => void
}

export const AudioMessagesContainer = ({
  events,
  translatedText,
  onPlayStart,
  onPlayStop,
  isPlaying,
  isRecording,
  cursor,
  isScrollAtBottom,
  goToBottomScroll,
}: AudioMessagesContainerProps) => {
  return (
    <>
      <Toast
        show={!isScrollAtBottom}
        animation={true}
        className="toast-scroll-message mt-4 me-4"
      >
        <Toast.Body>
          <div className="d-flex justify-content-between">
            <strong className="text-info text-md-start toast-text">
              Hay más mensajes abajo
            </strong>
            <button
              className="btn btn-info pull-right"
              onClick={goToBottomScroll}
            >
              Ver
            </button>
          </div>
        </Toast.Body>
      </Toast>
      <div className="audio-messages-container">
        {events.map((uiText, i) => (
          <div className="inner-text-column" key={`event-${i}`}>
            {uiText.type === ActionType.UI ? (
              <div className="ui-actions">
                <div>{uiText.msg}</div>
              </div>
            ) : uiText.type === ActionType.BOT ? (
              <BotMessageBox message={uiText as UiTextBot} />
            ) : (
              <>
                <TranscriptDisplayer
                  key={`${i}or`}
                  cursor={cursor}
                  message={uiText as UiTextTranscription}
                  hasAudio={true}
                  isPlaying={isPlaying}
                  isDisabled={isPlaying || isRecording}
                  onPlayStart={onPlayStart}
                  onPlayStop={onPlayStop}
                  translatedText={
                    uiText.id !== "-1" ? translatedText[uiText.id] : null
                  }
                />
              </>
            )}
          </div>
        ))}
      </div>
    </>
  )
}
