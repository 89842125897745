import {
  capture,
  reproduceAudioBuffer,
} from "@actionatics/rtstt-shared/build/AudioManager"
import { CaptureMetadata } from "@actionatics/rtstt-shared/build/AudioManager/capture"
import { MediaRecorderModule } from "@actionatics/rtstt-shared/build/AudioManager/Modules/MediaRecorderModule"
import { RawRecorderModule } from "@actionatics/rtstt-shared/build/AudioManager/Modules/RawRecorderModule"
import { Dispatch } from "redux"
import {
  AUDIO_PLAY_START,
  AUDIO_PLAY_STOP,
  AUDIO_RECORD_END,
  AUDIO_RECORD_START,
  AUDIO_UPDATE_CURSOR,
  UI_ADD_CUSTOM_EVENT,
} from "../actions"

import { loadOggBlobAsAudioBuffer } from "@actionatics/rtstt-shared/build/AudioManager/load"
import { ApiClient } from "api/ApiClient"
import { RootState } from "../store"
import { getAssemblyAIConfig } from "./audio-configs/assemblyAIConfig"
import { getFakeProcessorConfig } from "./audio-configs/FakeProcessorConfig"

const USE_SIMULATION = process.env.REACT_APP_USE_SIMULATION === "true"

let metadata: CaptureMetadata
let audioBuffer: AudioBuffer
let stopReproducing: null | Function = null

export const audioListeners = (dispatch: Dispatch) => ({
  [AUDIO_RECORD_START.type]: async (
    _: any,
    { user, devices: { devices, selected } }: RootState
  ) => {
    const msgId = `${new Date().getTime()}`
    dispatch(
      UI_ADD_CUSTOM_EVENT({
        id: msgId,
        message: "Recording about to start. Connecting...",
      })
    )
    let assemblyAIToken = ""

    if (USE_SIMULATION === false) {
      try {
        const api = new ApiClient(user.token)
        assemblyAIToken = await api.getAssemblyToken()
      } catch (error) {
        UI_ADD_CUSTOM_EVENT({
          id: msgId,
          message: "Stopping recorder, we could NOT connect",
        })
        dispatch(AUDIO_RECORD_END())
        return
      }
    }

    const deviceId = devices[selected].deviceId
    const modules = [
      new RawRecorderModule([
        USE_SIMULATION
          ? getFakeProcessorConfig(dispatch)
          : getAssemblyAIConfig(dispatch, assemblyAIToken, msgId),
      ]),
      new MediaRecorderModule(async (blob) => {
        audioBuffer = await loadOggBlobAsAudioBuffer(blob)
      }),
    ]

    metadata = await capture(deviceId, modules, 1, 16000)
  },
  [AUDIO_RECORD_END.type]: async (
    _: any,
    { audio, devices: { devices, selected } }: RootState
  ) => {
    metadata.stop()
  },
  [AUDIO_PLAY_START.type]: async (
    range: { from: number; to: number } | undefined,
    { ui }: RootState
  ) => {
    const onUpdateCursor = (position: number) => {
      dispatch(AUDIO_UPDATE_CURSOR(position))
    }

    if (range) {
      stopReproducing = reproduceAudioBuffer(
        audioBuffer,
        range.from,
        range.to ? range.to - range.from : undefined,
        onUpdateCursor
      )
    } else {
      stopReproducing = reproduceAudioBuffer(
        audioBuffer,
        0,
        undefined,
        onUpdateCursor
      )
    }
  },
  [AUDIO_PLAY_STOP.type]: async (
    range: { from: number; to: number } | undefined,
    { ui }: RootState
  ) => {
    if (stopReproducing) {
      stopReproducing()
      stopReproducing = null
    }
  },
})
