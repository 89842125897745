import { API_WS_URL } from "config"
import { Dispatch } from "redux"
import { io } from "socket.io-client"
import { WS_BOT_INITS, WS_JOIN } from "state/actions/ws"
import { ListenerFunction } from "state/types"
import { wsOnConnect } from "./ws/ws-on-connect"
import { wsOnDisconnect } from "./ws/ws-on-disconnect"

export const wsListeners = (dispatch: Dispatch) => ({
  // used to joing a meeting
  [WS_JOIN.type]: (async (payload, state) => {
    const socket = io(API_WS_URL, {
      transports: ["websocket", "polling", "flashsocket"],
    })
    socket.on("connect", () => wsOnConnect(socket, dispatch, payload.sessionId))
    socket.on("disconnect", () => wsOnDisconnect())
  }) as ListenerFunction<typeof WS_JOIN>,

  // Used by the bot to get the details of a meeting to start sending
  [WS_BOT_INITS.type]: (async (payload, state) => {
    const socket = io(API_WS_URL, {
      transports: ["websocket", "polling", "flashsocket"],
    })
    socket.on("connect", () => wsOnConnect(socket, dispatch, payload.sessionId))
    socket.on("disconnect", () => wsOnDisconnect())
  }) as ListenerFunction<typeof WS_BOT_INITS>,
})
