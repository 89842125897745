import { debounce, DebouncedFunc } from "lodash"
import { RootState } from "state/reducers"

type ReducerDebounce = (obj: any, state: RootState, signal?: AbortSignal) => any

const cache = {} as { [id: string]: DebouncedFunc<ReducerDebounce> }
const signals = {} as { [id: string]: AbortController }

export const debounceByProp =
  (idKey: string, isFinalKey: string, fn: ReducerDebounce) =>
  (obj: any, state: RootState) => {
    const id = obj[idKey]
    const isFinal = obj[isFinalKey]
    if (isFinal) {
      if (cache[id] !== undefined) {
        cache[id].cancel()
        signals[id].abort()
        delete cache[id]
        delete signals[id]
      }
      return fn(obj, state)
    }

    if (cache[id] === undefined) {
      signals[id] = new AbortController()
      cache[id] = debounce(fn, 1000, { maxWait: 1000 })
    }

    return cache[id](obj, state, signals[id].signal)
  }

export const debounceTime = (
  wait = 1000,
  maxWait = 1000,
  fn: ReducerDebounce
) => debounce(fn, wait, { maxWait })
