import { useEffect } from "react"
import Joyride, { CallBackProps, STATUS } from "react-joyride"
import { useDispatch, useSelector } from "react-redux"
import { UI_SHOW_INSTRUCTIONS } from "state/actions"
import { RootState } from "state/store"

const LS_KEY = "help_shown"
const LS_VERSION = "1"

export const HelpOverlay = () => {
  const show = useSelector((state: RootState) => state.ui.showInstructions)
  const dispatch = useDispatch()

  const callback = (joyrideState: CallBackProps) => {
    if (
      ([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(
        joyrideState.status
      ) === true
    ) {
      dispatch(UI_SHOW_INSTRUCTIONS(false))
    }
  }

  useEffect(() => {
    if (localStorage.getItem(LS_KEY) !== LS_VERSION) {
      dispatch(UI_SHOW_INSTRUCTIONS(true))
      localStorage.setItem(LS_KEY, LS_VERSION)
    }
  }, [dispatch])

  if (!show) {
    return null
  }

  return (
    <Joyride
      callback={callback}
      locale={{
        next: "siguiente",
        close: "cerrar",
        skip: "saltar",
        back: "atrás",
      }}
      steps={[
        {
          target: ".device-selector",
          content: "Selecciona el dispositivo de entrada de audio.",
        },
        {
          target: ".record-button",
          content:
            "Haz clic en este ícono para comenzar a transcribir el audio.",
        },
      ]}
    />
  )
}
