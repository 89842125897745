import { FaMicrophoneAlt } from "react-icons/fa"
import { TutorialItem } from "../../TutorialItem/TutorialItem"

export const InitialHelpWindows = ({ prefixID = "" }: { prefixID: string }) => {
  return (
    <div>
      <TutorialItem
        id={`${prefixID}-1`}
        n={1}
        urlNext={`#${prefixID}-2`}
        title="Abrir configuración de sonido"
        imageUrl="/images/windows/es/1.2.png"
        imageClass="img-75"
        dscTop={
          <div>
            <p className="bg-light text-dark">
              Estas instrucciones son para windows 11. Si tienes una versión
              anterior, es posible que puedas seguir estos pasos pero
              encontrarás ligeras diferencias.
            </p>
            <p>
              Hacer clic derecho en el ícono de sonido en tu barra de tareas. Y
              abrir <code>“Configuración de sonido”</code> .
            </p>
          </div>
        }
      />
      <TutorialItem
        id={`${prefixID}-2`}
        n={2}
        urlNext={`#${prefixID}-3`}
        title="Haz clic en 'Más opciones de sonido'"
        imageUrl="/images/windows/es/2.2.jpeg"
        dscTop={
          <div>
            En la pantalla de configuración, dentro de{" "}
            <code>“Opciones avanzadas”</code> encontrarás{" "}
            <code>“Más opciones de sonido”</code>. Haz clic en esta opción.
          </div>
        }
      />
      <TutorialItem
        id={`${prefixID}-3`}
        urlNext={`#${prefixID}-4`}
        n={3}
        title="Haz clic en en la solapa 'Grabar'"
        imageUrl="/images/windows/es/3.3.png"
      />
      <TutorialItem
        id={`${prefixID}-4`}
        n={4}
        urlNext={`#${prefixID}-5`}
        title="Activa Stereo Mix"
        imageUrl="/images/windows/es/4.4.png"
        dscTop={
          <>
            En esta solapa, encontrarás <code>“Stereo Mix”</code> o{" "}
            <code>“Mezcla estéreo”</code>. Hazle clic con el botón derecho en el
            dispositivo y luego en <code>“Activar”</code>.
          </>
        }
        dscBottom={<>Luego de activar Stereo Mix, deberías verlo así:</>}
        imageUrl2="/images/windows/es/5.5.png"
      />
      <TutorialItem
        id={`${prefixID}-4`}
        n={4}
        urlNext={`#${prefixID}-5`}
        title="Activa Stereo Mix"
        imageUrl="/images/windows/es/4.4.png"
        imageClass="img-75"
        dscTop={
          <>
            En esta solapa, encontrarás <code>“Stereo Mix”</code> o{" "}
            <code>“Mezcla estéreo”</code>. Hazle clic con el botón derecho en el
            dispositivo y luego en <code>“Activar”</code>.
          </>
        }
        // dscBottom={<>Luego de activar Stereo Mix, deberías verlo así:</>}
        // imageUrl2="/images/windows/es/5.5.png"
      />
      <TutorialItem
        id={`${prefixID}-5`}
        n={5}
        urlNext={`#${prefixID}-6`}
        title="Configura propiedades de Stereo Mix"
        imageUrl="/images/windows/es/win-props-click.jpeg"
        imageClass="img-75"
        dscTop={
          <>
            Vuelve a hacer clic derecho en <code>Stereo Mix</code> y selecciona
            <code> propiedades</code>.
          </>
        }
      />
      <TutorialItem
        id={`${prefixID}-6`}
        n={6}
        urlNext={`#${prefixID}-7`}
        title="Configura propiedades de Stereo Mix"
        imageUrl="/images/windows/es/win-prop-window-tab.jpeg"
        dscTop={
          <>
            Haz clic en la pestaña <code>Escuchar</code>
          </>
        }
        dscBottom={
          <ul>
            <li>
              Asegúrate de que el tilde <code>Escuchar este dispositivo</code>{" "}
              esté tildado.
            </li>
            <li>
              <p>
                {" "}
                En <code>Reproducir mediante este dispositivo</code>, selecciona{" "}
                <u>tus auriculares</u>.
              </p>
              <p>
                <small>
                  <b>Nota: </b>
                  No recomendamos seleccionar el disposivo predeterminado porque
                  seguirá funcionando incluso cuando estés usando los speakers y
                  puede producir acoples.
                </small>
              </p>
            </li>
            <li>
              Haz clic en <code>Aceptar</code>
            </li>
          </ul>
        }
        imageUrl2="/images/windows/es/win-prop-window-listen.jpeg"
      />
      <TutorialItem
        id={`${prefixID}-7`}
        n={7}
        imageUrl="/images/windows/es/6.6.png"
        imageClass="img-50"
        title="Todo listo !!!"
        dscTop={
          <>
            <p>
              Crea una nueva transcripción entrando a:
              <a href="https://app.myinterpreter.co/recorder">
                https://app.myinterpreter.co/recorder
              </a>
            </p>

            <p>
              Y haz click en{" "}
              <FaMicrophoneAlt
                size={20}
                className="start-button-ref"
              ></FaMicrophoneAlt>{" "}
              para comenzar la grabacion:
            </p>
          </>
        }
        dscBottom="Esperamos que disfrutes de la experiencia."
      />
    </div>
  )
}
