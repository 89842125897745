import { InitialHelp } from "components/Help/InitialHelp"
import { LeftMenu } from "components/LeftMenu"
import { FaArrowLeft } from "react-icons/fa"

export const HelpPage = () => {
  return (
    <div className="main">
      <div className="block-left-bar d-flex align-items-end vh-100 justify-content-between mt-2">
        <LeftMenu
          hideRecordingButtons={true}
          isRecording={false}
          isPlaying={false}
          onRecordStop={() => null}
          onRecordStart={() => null}
          onPlayStart={() => null}
          onPlayStop={() => null}
        />
      </div>
      <div className="block-top-bar p-3">
        <a href="/recorder">
          <FaArrowLeft fontSize={30} /> Go back to recorder
        </a>
      </div>
      <div className="block-text-container">
        <InitialHelp enableOnboardingButon={false} />
      </div>
    </div>
  )
}
