import { Dispatch } from "redux"
import { getListeners } from "./listeners"

export const listenersMiddleware = ({
  dispatch,
  getState,
}: {
  dispatch: Dispatch
  getState: () => any
}) => {
  const listeners = getListeners(dispatch)

  return (next: any) => (action: { type: string; payload: any }) => {
    const result = next(action)
    // @ts-ignore
    if (listeners[action.type]) {
      // @ts-ignore
      listeners[action.type](action.payload, getState())
    }
    return result
  }
}
