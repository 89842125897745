import { useEffect, useRef } from "react"
import Figure from "react-bootstrap/esm/Figure"
import { useLocation } from "react-router-dom"
import "./tutorialItemStyles.scss"

type TutorialItemProps = {
  n?: number
  id: string
  dscTop?: string | JSX.Element
  dscBottom?: string | JSX.Element
  dscBottom2?: string | JSX.Element
  element?: JSX.Element | null
  imageUrl?: string
  imageUrl2?: string
  title?: string | JSX.Element
  imageClass?: string
  imageClass2?: string
  urlNext?: string
}

export const TutorialItem = ({
  n = 0,
  id = "",
  title = "",
  dscTop = "",
  imageUrl = "",
  imageUrl2 = "",
  imageClass = "",
  imageClass2 = "",
  dscBottom = "",
  dscBottom2 = "",
  element = null,
  urlNext = "",
}: TutorialItemProps) => {
  const ref = useRef<HTMLDivElement | null>(null)

  const location = useLocation()

  useEffect(() => {
    const goTo = location.hash.slice(1)
    if (goTo === id) {
      ref.current?.scrollIntoView({ behavior: "smooth" })
    }
  }, [location, id])

  return (
    <div className="tutorial-item shadow-lg bg-white rounded" id={id} ref={ref}>
      {title && (
        <h3 className="tutorial-item-title">
          {" "}
          {n !== 0 && (
            <a className="tutorial-item-number" href={`#${id}`}>
              {n}
            </a>
          )}
          <span className="tutorial-item-title-text">{title}</span>
        </h3>
      )}

      {dscTop && <div className="tutorial-item-dsc">{dscTop}</div>}
      {imageUrl && (
        <Figure.Image
          className={`${imageClass} tutorial-item-image shadow-lg`}
          alt="launcher"
          src={imageUrl}
        />
      )}
      {element ? element : null}
      {dscBottom && <div className="tutorial-item-dsc">{dscBottom}</div>}
      {imageUrl2 && (
        <Figure.Image
          className={`${
            imageClass2 === "" ? imageClass : imageClass2
          } tutorial-item-image shadow-lg`}
          alt="launcher"
          src={imageUrl2}
        />
      )}
      {dscBottom2 && <div className="tutorial-item-dsc">{dscBottom2}</div>}

      <div className="text-center">
        {urlNext && (
          <a href={urlNext} className="btn btn-warning">
            Siguiente
          </a>
        )}
      </div>
    </div>
  )
}
