import { ActionType } from "@actionatics/rtstt-shared/build/dataTypes/UiText"
import { FakeSpeechProcessor } from "@actionatics/rtstt-shared/build/Transcribe/FakeSpeechProcessor"
import { Dispatch } from "redux"
import {
  TRACK_USAGE,
  TRANSCRIPT_TEXT_ADD,
  UI_TRANSLATED_TEXT_INIT,
} from "../../actions"

class FakeRandomly extends FakeSpeechProcessor {
  // 5 chunks of noise and the 5 of silence
  sequenceSize = 5

  // 5 chunks of noise and the 5 of silence
  _count = 0

  processNewData(bufferData: Float32Array): void {
    this.sampleRate = bufferData.length

    let value = 0.001
    this._count++
    if (this._count < this.sequenceSize) {
      value = 0.2
    } else if (this._count > this.sequenceSize * 2) {
      // resets the counter
      this._count = 0
    }

    const data = new Float32Array(bufferData.length)
    for (let i = 0; i < data.length; i++) {
      data[i] = value
    }

    super.processNewData(data)
  }
}

export const getFakeProcessorConfig = (
  dispatch: Dispatch,
  // there are 2 modes: either generate them randomly or react to sound
  randomlyMakeMessages = true
) => {
  const ClassName = randomlyMakeMessages ? FakeRandomly : FakeSpeechProcessor
  return new ClassName().subscribe((message) => {
    if (message && message.message) {
      const id = `id-${message.startTime.toFixed(2)}`
      dispatch(
        TRANSCRIPT_TEXT_ADD({
          id,
          msg: message.message,
          type: ActionType.SPEECH,
          date: message.startTime.toFixed(2),
          metadata: message,
          startTime: message.startTime,
          endTime: message.endTime,
        })
      )
      dispatch(
        UI_TRANSLATED_TEXT_INIT({
          id,
          textToTranslate: message.message,
          isFinal: !message.isPartial,
        })
      )
      if (!message.isPartial) {
        dispatch(TRACK_USAGE())
      }
    }
  })
}
