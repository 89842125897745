import { useAuth0 } from "@auth0/auth0-react"
import { FAQ } from "components/FAQ/FAQ"
import { useNavigate } from "react-router-dom"
import "../../styles/pages/home.scss"

const ENTRY_POINT = "/setup-meeting"

type NotLoggedInProps = { loginWithRedirect: () => any }

export const NotLoggedIn = ({ loginWithRedirect }: NotLoggedInProps) => {
  return (
    <button
      className="btn btn-primary btn-lg"
      onClick={() => loginWithRedirect()}
    >
      Registrarse / Login
    </button>
  )
}

export const LoggedIn = ({ logout }: { logout: () => any }) => {
  return (
    <div className="d-flex">
      <a className="btn btn-primary btn-lg" href={ENTRY_POINT}>
        Probar
      </a>
      <button
        className="btn  btn-outline-primary btn-lg py-0"
        onClick={() => logout()}
      >
        Logout
      </button>
    </div>
  )
}

const Home = () => {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0()
  const navigate = useNavigate()

  const onTestClick = () => {
    if (isAuthenticated) {
      navigate(ENTRY_POINT)
    } else {
      loginWithRedirect({
        screen_hint: "signup",
      })
    }
  }

  return (
    <div className="home-page">
      <nav
        id="navbar-spa"
        className="navbar navbar-expand-lg navbar-light bg-light"
      >
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            <li className="nav-item">
              <a className="nav-link active" href="#product">
                Producto
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link active" href="#faq">
                Preguntas Frequentes
              </a>
            </li>
          </ul>
        </div>
        <div className="ml-auto ">
          <div className="me-4">
            {isAuthenticated ? (
              <LoggedIn
                logout={() => logout({ returnTo: window.location.href })}
              />
            ) : (
              <NotLoggedIn loginWithRedirect={loginWithRedirect} />
            )}
          </div>
        </div>
      </nav>

      <div className="content-spa" data-spy="scroll" data-target="#navbar-spa">
        <section id="product" className="py-5">
          <div className="container product-background">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-9 col-md-12 mb-3 mb-lg-0 product-presentation-bg">
                <div className="product-presentation-spacer"></div>
              </div>
              <div className="col-lg-3 col-md-12 d-flex justify-content-center right-column">
                <div className="d-flex flex-column">
                  <button
                    type="button"
                    className="btn btn-primary btn-lg mb-5 product-button"
                    onClick={onTestClick}
                  >
                    Comienza una prueba
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-primary btn-lg product-button"
                    onClick={() => navigate("#product")}
                  >
                    Quiero saber más
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/*"<!-- Video demo section -->"*/}
        {/* <section className="container-fluid py-5 bg-light">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-9 col-md-12 mb-3 mb-lg-0">
              <div className="embed-responsive embed-responsive-16by9">
              placeholder
              </div>
            </div>
          </div>
        </section> */}

        <section id="product" className="container-fluid py-5">
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-9 col-md-12 mb-3 mb-lg-0">
                <h2 className="">¿Cómo puede servirme ?</h2>
                <p>
                  Si tienes una videollamada y no quieres perderte detalles,
                  esta herramienta es para ti!
                </p>{" "}
                <p>
                  {" "}
                  MyInterpreter captura el sonido, al mismo tiempo que
                  transcribe y traduce el audio <b>en inglés</b> en tiempo real.
                </p>
                <p>
                  Al tener la transcripción en formato texto, puedes buscar
                  rápidamente y re-escuchar partes que no hayas entendido.
                </p>
                <p className="text-center">
                  <img
                    width="75%"
                    src="/images/home/screenshot.png"
                    alt="screenshot"
                    className="rounded shadow"
                  />
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* FAQ */}
        <section id="faq" className="container-fluid py-5">
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-9 col-md-12 mb-3 mb-lg-0">
                <FAQ></FAQ>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default Home
