import Figure from "react-bootstrap/esm/Figure"
import "./boxoptionStyles.scss"

type BoxOptionProps = {
  dscTop?: string | JSX.Element
  children?: string | JSX.Element
  element?: JSX.Element | null
  imageUrl?: string
  imageUrl2?: string
  title?: string | JSX.Element
  imageClass?: string
  onClick?: (...args: any) => any
  buttonLabel?: string | JSX.Element
}

export const BoxOption = ({
  title = "",
  dscTop = "",
  imageUrl = "",
  imageUrl2 = "",
  imageClass = "",
  children = "",
  element = null,
  buttonLabel = "",
  onClick = () => null,
}: BoxOptionProps) => {
  return (
    <div className="box-option shadow-lg bg-white rounded">
      {title && <h3 className="box-option-title">{title}</h3>}

      {dscTop && <div className="box-option-dsc">{dscTop}</div>}
      {imageUrl && (
        <Figure.Image
          className={`${imageClass} box-option-image shadow-lg`}
          alt="launcher"
          src={imageUrl}
        />
      )}
      {element ? element : null}
      {children && <div className="box-option-dsc">{children}</div>}
      {imageUrl2 && (
        <Figure.Image
          className={`${imageClass} box-option-image shadow-lg`}
          alt="launcher"
          src={imageUrl2}
        />
      )}

      <div className="text-center">
        {buttonLabel && (
          <button onClick={onClick} className="btn btn-primary btn-lg">
            {buttonLabel}
          </button>
        )}
      </div>
    </div>
  )
}
