import { ApiClient } from "api/ApiClient"
import { Dispatch } from "redux"
import {
  CONFERENCE_GET_MEETING_DETAILS_ERROR,
  CONFERENCE_GET_MEETING_DETAILS_INIT,
  CONFERENCE_GET_MEETING_DETAILS_SUCCESS,
  CONFERENCE_GET_ZOOM_SIGNATURE_ERROR,
  CONFERENCE_GET_ZOOM_SIGNATURE_INIT,
  CONFERENCE_GET_ZOOM_SIGNATURE_SUCCESS,
} from "state/actions/conference"
import { RootState } from "state/store"
import { Payload } from "state/types"

const apiKey = "BOT_SECRET"

export const conferenceListeners = (dispatch: Dispatch) => ({
  [CONFERENCE_GET_MEETING_DETAILS_INIT.type]: async (
    sessionId: Payload<typeof CONFERENCE_GET_MEETING_DETAILS_INIT>,
    state: RootState
  ) => {
    const api = new ApiClient("", apiKey)
    try {
      const meetingDetails = await api.getMeetingDetails(sessionId)
      dispatch(CONFERENCE_GET_MEETING_DETAILS_SUCCESS({ meetingDetails }))
    } catch (err) {
      dispatch(CONFERENCE_GET_MEETING_DETAILS_ERROR(err))
    }
  },
  [CONFERENCE_GET_ZOOM_SIGNATURE_INIT.type]: async (
    payload: Payload<typeof CONFERENCE_GET_ZOOM_SIGNATURE_INIT>,
    state: RootState
  ) => {
    const api = new ApiClient("", apiKey)
    try {
      const response = await api.getZoomSignature(payload)
      dispatch(CONFERENCE_GET_ZOOM_SIGNATURE_SUCCESS(response))
    } catch (err) {
      dispatch(CONFERENCE_GET_ZOOM_SIGNATURE_ERROR(err))
    }
  },
})
