import React, { useRef, useState } from "react"
import { FaCopy } from "react-icons/fa"
import "./codeStyles.scss"

type CodeProps = {
  children: string | React.ReactElement
  theme?: "code" | "link-small"
}
export const Code = ({ children, theme }: CodeProps) => {
  const [isCopied, setIsCopied] = useState(false)
  const ref = useRef<HTMLSpanElement>(null)

  const copyToClipboard = () => {
    if (ref.current && window !== null) {
      const range = document.createRange()
      range.selectNode(ref.current)
      window.getSelection()!.removeAllRanges()
      window.getSelection()!.addRange(range) // to select text
      document.execCommand("copy")
      window.getSelection()!.removeAllRanges() // to deselect
      setIsCopied(true)
      setTimeout(() => {
        setIsCopied(false)
      }, 2000)
    }
  }

  return (
    <div className={`code-container ${theme}`}>
      <button
        className={`btn pull-right btn-copy ${
          isCopied ? "btn-primary" : "btn-success"
        }`}
        onClick={copyToClipboard}
      >
        {isCopied ? "Copied !" : "Copy"} &nbsp;
        <FaCopy />
      </button>
      <span ref={ref}>{children}</span>
    </div>
  )
}
