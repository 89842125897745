import {
  BotState,
  StreamState,
} from "@actionatics/rtstt-shared/build/dataTypes/BotState"
import UiText, {
  ActionType,
} from "@actionatics/rtstt-shared/build/dataTypes/UiText"
import { createReducer } from "@reduxjs/toolkit"
import {
  UI_BOT_STATE,
  UI_SESSION_CREATE_ERROR,
  UI_SESSION_CREATE_INIT,
  UI_SESSION_CREATE_SUCCESS,
} from "state/actions/ui"
import {
  TRANSCRIPT_TEXT_ADD,
  UI_ADD_BOT_EVENT,
  UI_ADD_CUSTOM_EVENT,
  UI_SHOW_INSTRUCTIONS,
  UI_TRANSLATED_TEXT_ADD,
} from "../actions"

const initialState = {
  id: "",
  showInstructions: false,
  stateBot: BotState.INACTIVE,
  stateStream: StreamState.NOT_STARTED,
  time: 0,
  events: [] as UiText[],
  translatedTexts: {} as {
    [id: string]: string
  },
  setupMeetingIsJoining: false,
  setupMeetingisNewMeeting: false,
}

export default createReducer(initialState, (builder) => {
  builder.addCase(TRANSCRIPT_TEXT_ADD, (state, { payload }) => {
    // receives from main process the speech to text content "UiText"
    const replaceId = state.events.findIndex(
      (event) => event.type === ActionType.SPEECH && event.id === payload.id
    )

    if (replaceId !== -1) {
      const previous = state.events[replaceId]
      state.events[replaceId] = payload
      state.translatedTexts[payload.id] = state.translatedTexts[previous.id]
    } else {
      state.events.push(payload)
    }
  })
  builder.addCase(UI_TRANSLATED_TEXT_ADD, (state, { payload }) => {
    // receives from main process the translated text content with a map format like : {[id: string]: string}
    state.translatedTexts = { ...state.translatedTexts, ...payload }
  })
  builder.addCase(
    UI_ADD_CUSTOM_EVENT,
    (state, { payload: { message, id } }) => {
      const replaceId = id
        ? state.events.findIndex(
            (event) => event.type === ActionType.UI && id === event.id
          )
        : -1

      const date = new Date()
      const dataToSend: UiText = {
        msg: message,
        type: ActionType.UI,
        date: `${date.getHours()}:${date.getMinutes()}`,
        id: id || `${date.getTime()}`,
      }

      if (replaceId !== -1) {
        state.events[replaceId] = dataToSend
      } else {
        state.events.push(dataToSend)
      }
    }
  )

  // OPEN_AI EVENT
  builder.addCase(UI_ADD_BOT_EVENT, (state, { payload }) => {
    state.events.push(payload)
  })

  builder.addCase(UI_SHOW_INSTRUCTIONS, (state, { payload }) => {
    state.showInstructions = payload
  })
  builder.addCase(UI_SESSION_CREATE_INIT, (state, { payload }) => {
    if (payload.isCustomClient) {
      state.setupMeetingisNewMeeting = true
    } else {
      state.setupMeetingIsJoining = true
    }
  })
  builder.addCase(UI_SESSION_CREATE_SUCCESS, (state, { payload }) => {
    state.setupMeetingIsJoining = false
    state.setupMeetingisNewMeeting = false
    state.id = payload
  })
  builder.addCase(UI_SESSION_CREATE_ERROR, (state) => {
    state.setupMeetingIsJoining = false
    state.setupMeetingisNewMeeting = false
  })
  builder.addCase(UI_BOT_STATE, (state, { payload }) => {
    state.stateBot = payload.bot
    state.stateStream = payload.stream
    state.time = payload.time
  })
})
