import { Dispatch } from "redux"
import { audioListeners } from "./audio"
import { conferenceListeners } from "./conference"
import { devicesListeners } from "./devices"
import { trackListeners } from "./track"
import { uiListeners } from "./ui"
import { wsListeners } from "./ws"

export const getListeners = (dispatch: Dispatch) => {
  return {
    ...devicesListeners(dispatch),
    ...audioListeners(dispatch),
    ...uiListeners(dispatch),
    ...trackListeners(dispatch),
    ...wsListeners(dispatch),
    ...conferenceListeners(dispatch),
    ...conferenceListeners(dispatch),
  }
}
