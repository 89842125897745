import { JITSI_HOST } from "config"

const MEETING_NAME_LENGTH = 10

export const makeMeeting = () => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
  let result = ""
  for (var i = 0; i < MEETING_NAME_LENGTH; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length))
  }
  return `https://${JITSI_HOST}/my-interpreter-${result}`
}
