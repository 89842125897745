import { createReducer } from "@reduxjs/toolkit"
import { UserData } from "../../types/userTypes"
import { USER_SET_DATA, USER_SET_TOKEN } from "../actions"

const initialState = {
  token: "",
  data: null as UserData | null,
}

export const userReducer = createReducer(initialState, (builder) => {
  builder.addCase(USER_SET_TOKEN, (state, { payload }) => {
    state.token = payload
  })
  builder.addCase(USER_SET_DATA, (state, { payload }) => {
    state.data = payload
  })
})

export default userReducer
