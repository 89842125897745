import { useAuth0 } from "@auth0/auth0-react"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { EXT_STATE } from "state/actions/extension"
import { MESSAGE } from "utils/messages"

let authToken = ""

const UPDATE_INTERVAL = 2000

/**
 *
 * @returns Checks if the extension is active, if it is, it communicates the authToken
 */
export const AuthExtension = () => {
  const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0()
  const dispatch = useDispatch()

  useEffect(() => {
    const interval = setInterval(() => {
      if (chrome) {
        window.postMessage(
          {
            type: MESSAGE.MYINT_WEB_CSAUTH_REQUEST_UPDATE_STATE,
          },
          "*"
        )
      }
    }, UPDATE_INTERVAL)

    return () => {
      clearInterval(interval)
    }
  }, [dispatch])

  // register listener of events that come from the extension contentScript
  useEffect(() => {
    const listenerEventsFromExtension = ({ data }: { data: any }) => {
      if (!data || !data.type) return
      switch (data.type) {
        // receives request to send token
        case MESSAGE.MYINT_CSAUH_WEB_UPDATE_TOKEN:
          window.postMessage(
            {
              type: MESSAGE.MYINT_WEB_CSAUH_UPDATE_TOKEN,
              payload: authToken,
            },
            "*"
          )
          break
        // receives updated state from content script
        case MESSAGE.MYINT_CSAUTH_WEB_UPDATED_STATE:
          dispatch(EXT_STATE(data.payload))
          break
      }
    }

    window.addEventListener("message", listenerEventsFromExtension)

    return () => {
      window.removeEventListener("message", listenerEventsFromExtension)
    }
  }, [dispatch])

  // keeps global variable authToken updated
  useEffect(() => {
    const getAccessToken = async () => {
      authToken = await getAccessTokenSilently()
    }

    if (isAuthenticated === true && chrome) {
      getAccessToken()
    }
  }, [isAuthenticated, isLoading, getAccessTokenSilently])
  return <></>
}
