export const AWS_ACCESS_KEY_ID = process.env.REACT_APP_AWS_ACCESS_KEY_ID || ""
export const AWS_SECRET_ACCESS_KEY =
  process.env.REACT_APP_AWS_SECRET_ACCESS_KEY || ""
export const AWS_REGION = process.env.REACT_APP_AWS_REGION || ""
export const API_URL = process.env.REACT_APP_API_URL || ""

export const API_WS_URL = process.env.REACT_APP_API_WS_URL || ""
export const TRANSLATE_API =
  process.env.REACT_APP_TRANSLATE_API ||
  "https://translate.myinterpreter.co/translate"

export const DOWNLOAD_URL = API_URL + "/../downloads/"

export const JITSI_HOST = "meet.mayfirst.org"
export const BOT_NAME = "Luna"

export const LANGUAGE_CODE = "es"
