import { createBrowserRouter } from "react-router-dom"
import { HelpPage } from "./help/HelpPage"
import Home from "./home"
import Login from "./login"
import { MeetingBotPage } from "./meeting-bot/MeetingBotPage"
import { PrivacyPage } from "./privacy/PrivacyPage"
import Recorder from "./recorder"
import { SetupMeeting } from "./setup-meeting/SetupMeetingPage"
import StatusPage from "./status/StatusPage"
import { TranscriptPage } from "./transcript/TranscriptPage"

const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/recorder",
    element: <Recorder />,
  },
  {
    path: "/status",
    element: <StatusPage />,
  },
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/help",
    element: <HelpPage />,
  },
  {
    path: "/setup-meeting",
    element: <SetupMeeting />,
  },
  {
    path: "/transcript/:sessionId",
    element: <TranscriptPage />,
  },
  {
    path: "/meeting-bot/:sessionId",
    element: <MeetingBotPage />,
  },
  {
    path: "/privacy",
    element: <PrivacyPage />,
  },
])

export default router
