import { useAuth0 } from "@auth0/auth0-react"
import { BaseApp } from "components/App/BaseApp/BaseApp"
import { __ } from "i18n"
import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react"
import { Button, Spinner } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { USER_SET_TOKEN } from "state/actions"
import { UI_SESSION_CREATE_INIT } from "state/actions/ui"
import { RootState } from "state/store"
import { makeMeeting } from "utils/makeMeetingUtils"
import "./setupMeeting.scss"

export const SetupMeeting = () => {
  const dispatch = useDispatch()
  const navigator = useNavigate()

  const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0()

  useEffect(() => {
    ;(async () => {
      const token = await getAccessTokenSilently()
      dispatch(USER_SET_TOKEN(token as string))
    })()
  }, [getAccessTokenSilently, dispatch])

  useEffect(() => {
    if (!isAuthenticated && isLoading === false) {
      window.location.replace("/")
    }
  }, [isAuthenticated, isLoading])

  useEffect(() => {
    ;(async () => {
      const token = await getAccessTokenSilently()
      dispatch(USER_SET_TOKEN(token as string))
    })()
  }, [getAccessTokenSilently, dispatch])

  useEffect(() => {
    if (!isAuthenticated && isLoading === false) {
      window.location.replace("/")
    }
  }, [isAuthenticated, isLoading])

  const isJoining = useSelector(
    (state: RootState) => state.ui.setupMeetingIsJoining
  )

  const isNewMeeting = useSelector(
    (state: RootState) => state.ui.setupMeetingisNewMeeting
  )

  const sessionId = useSelector((state: RootState) => state.ui.id)
  const recordingIdRef = useRef<string>(sessionId)

  useEffect(() => {
    if (recordingIdRef.current !== sessionId) {
      navigator("/transcript/" + sessionId)
    }
  }, [sessionId, navigator])

  const [meetingDetails, setMeetingDeetails] = useState("")
  const onChangeMeetinDetails = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setMeetingDeetails(event.target.value)
    },
    [setMeetingDeetails]
  )

  const onSubmitCreate = useCallback(() => {
    const meetingURL = makeMeeting()
    dispatch(
      UI_SESSION_CREATE_INIT({
        meetingDetails: meetingURL,
        isCustomClient: true,
      })
    )
  }, [dispatch])

  const onSubmitJoin = useCallback(() => {
    dispatch(UI_SESSION_CREATE_INIT({ meetingDetails, isCustomClient: false }))
  }, [meetingDetails, dispatch])

  return (
    <BaseApp className="setup-meeting-page">
      <div className="container">
        <div className="setup-meeting-page-container">
          <label htmlFor="meetingDetails" className="form-label">
            {__("CREATE_A_NEW_MEETING_DESCRIPTION")}
          </label>
          <div className="text-center mb-5">
            <Button
              className="btn btn-primary btn-lg button-ask"
              disabled={isNewMeeting || isJoining}
              onClick={onSubmitCreate}
            >
              {isNewMeeting && (
                <>
                  <Spinner
                    animation="border"
                    role="status"
                    color="light"
                  ></Spinner>
                  &nbsp;
                </>
              )}
              <span className="fw-bold fs-2">
                {__("CREATE_A_NEW_MEETING_BTN")}
              </span>
            </Button>
          </div>
          <div className="text-center mt-3 mb-3">OR</div>

          <label htmlFor="meetingDetails" className="form-label ">
            {__("INSERT_MEETING_DETAILS_LABEL")}
          </label>
          <input
            type="text"
            className="form-control setup-meeting-page-input"
            id="meetingDetails"
            value={meetingDetails}
            placeholder={__("INSERT_MEETING_DETAILS_PLACEHOLDER")}
            onChange={onChangeMeetinDetails}
            autoFocus
          />
        </div>
        <Button
          className="btn btn-primary btn-lg button-ask"
          disabled={meetingDetails === "" || isJoining}
          onClick={onSubmitJoin}
        >
          {isJoining && (
            <>
              <Spinner animation="border" role="status" color="light"></Spinner>
              &nbsp;
            </>
          )}
          <span className="fw-bold fs-2">{__("JOIN")}</span>
        </Button>
        <Link to={"/recorder"}>{__("RECORD_FROM_PC")}</Link>

        <div className="ww-explain">
          <h3 className="text-dark">{__("WHAT_WILL_HAPPEN")}</h3>
          <p className="text-dark">
            {__("WW_P1")} <br />
            {__("WW_P2")}
          </p>
          L
        </div>
      </div>
    </BaseApp>
  )
}
