import { ActionType } from "@actionatics/rtstt-shared/build/dataTypes/UiText"
import { AssemblyAISpeechProcessor } from "@actionatics/rtstt-shared/build/Transcribe/AssemblyAISpeechProcessor"
import { Dispatch } from "redux"
import {
  AUDIO_RECORD_END,
  TRACK_USAGE,
  TRANSCRIPT_TEXT_ADD,
  UI_ADD_CUSTOM_EVENT,
  UI_TRANSLATED_TEXT_INIT,
} from "../../actions"

export const getAssemblyAIConfig = (
  dispatch: Dispatch,
  assemblyAIToken: string,
  msgId: string
) => {
  return new AssemblyAISpeechProcessor(assemblyAIToken).subscribe(
    (message, eventData) => {
      if (message && message.message) {
        const id = `id-${message.startTime.toFixed(2)}`
        dispatch(
          TRANSCRIPT_TEXT_ADD({
            id,
            msg: message.message,
            type: ActionType.SPEECH,
            date: message.startTime.toFixed(2),
            metadata: message,
            startTime: message.startTime,
            endTime: message.endTime,
          })
        )
        dispatch(
          UI_TRANSLATED_TEXT_INIT({
            id,
            textToTranslate: message.message,
            isFinal: !message.isPartial,
          })
        )
        if (!message.isPartial) {
          dispatch(TRACK_USAGE())
        }
      } else if (eventData.error) {
        console.error("assemblyAI threw an error", eventData.metadata)
        dispatch(
          UI_ADD_CUSTOM_EVENT({
            message:
              eventData.messageText ||
              "There was an error with the connection. ",
          })
        )
        dispatch(AUDIO_RECORD_END())
      } else {
        switch (eventData.eventType) {
          case "onclose":
            dispatch(
              UI_ADD_CUSTOM_EVENT({
                message: "Recording Stopped",
              })
            )
            dispatch(AUDIO_RECORD_END())
            break
          case "onopen":
            dispatch(
              UI_ADD_CUSTOM_EVENT({
                id: msgId,
                message: "Recording",
              })
            )
            break
          default:
            // console.log("unkown message", eventData.metadata)
            break
        }
      }
    }
  )
}
