import { MSG_WEB_TO_EXTENSION } from "@actionatics/rtstt-shared/build/Communication/constants"
import { Participant } from "@actionatics/rtstt-shared/build/dataTypes/Participant"
import { useEffectOnce } from "hooks/useEffectOnce"
import { lazy, Suspense, useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import {
  CONFERENCE_GET_MEETING_DETAILS_INIT,
  CONFERENCE_GET_ZOOM_SIGNATURE_INIT,
} from "state/actions/conference"
import { RootState } from "state/store"
import { Payload } from "state/types"

const ZoomClient = lazy(
  () => import("components/VideoConferenceClients/ZoomClient")
)

const JitsiClient = lazy(
  () => import("components/VideoConferenceClients/JitsiClient")
)

function isZoomUrl(url: string) {
  const zoomUrlRegex = /^(https?:\/\/)?([\w.-]+\.)*zoom\.us(\/.*)?$/
  return zoomUrlRegex.test(url)
}

export const MeetingBotPage = () => {
  const { sessionId } = useParams()
  const dispatch = useDispatch()

  useEffectOnce(() => {
    dispatch(CONFERENCE_GET_MEETING_DETAILS_INIT(sessionId || ""))
  })

  const meetingDetails = useSelector(
    (state: RootState) => state.conference.meetingDetails
  )
  const zoomSignature = useSelector(
    (state: RootState) => state.conference.zoomSignature
  )
  const zoomSdkKey = useSelector(
    (state: RootState) => state.conference.zoomSdkKey
  )

  const onParticipantChanged = useCallback((participant: Participant) => {
    var data = {
      type: MSG_WEB_TO_EXTENSION,
      payload: participant,
    }
    window.postMessage(data, "*")
  }, [])

  const onZoomGetSignature = useCallback(
    (payload: Payload<typeof CONFERENCE_GET_ZOOM_SIGNATURE_INIT>) => {
      dispatch(CONFERENCE_GET_ZOOM_SIGNATURE_INIT(payload))
    },
    [dispatch]
  )

  if (meetingDetails && isZoomUrl(meetingDetails)) {
    return (
      <Suspense>
        <ZoomClient
          url={meetingDetails}
          onParticipantChanged={onParticipantChanged}
          onGetSignature={onZoomGetSignature}
          signature={zoomSignature}
          sdkKey={zoomSdkKey}
        />
      </Suspense>
    )
  }

  if (meetingDetails) {
    return (
      <Suspense>
        <JitsiClient
          url={meetingDetails}
          onParticipantChanged={onParticipantChanged}
        />
      </Suspense>
    )
  }

  return null
}
