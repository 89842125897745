import { createReducer } from "@reduxjs/toolkit"
import {
  AUDIO_PLAY_START,
  AUDIO_PLAY_STOP,
  AUDIO_RECORD_END,
  AUDIO_RECORD_START,
  AUDIO_UPDATE_CURSOR,
} from "../actions"

const initialState = {
  isPlaying: false,
  isRecording: false,
  cursor: 0,
  duration: 0,
  max: 0,
  pointsCount: 0,
}

export const audioReducer = createReducer(initialState, (builder) => {
  builder.addCase(AUDIO_PLAY_START, (state) => {
    state.isPlaying = true
  })
  builder.addCase(AUDIO_PLAY_STOP, (state) => {
    state.isPlaying = false
  })
  builder.addCase(AUDIO_RECORD_START, (state, { payload }) => {
    state.isRecording = true
  })
  builder.addCase(AUDIO_RECORD_END, (state, { payload }) => {
    state.isRecording = false
  })
  builder.addCase(AUDIO_UPDATE_CURSOR, (state, { payload }) => {
    state.cursor = payload
  })
})

export default audioReducer
