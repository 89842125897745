import {
  ActionType,
  UiTextTranscription,
} from "@actionatics/rtstt-shared/build/dataTypes/UiText"
import { __ } from "i18n"
import { FaPlay, FaStop } from "react-icons/fa"
import "./TranscriptDisplayerStyles.scss"

type TranscriptDisplayerProps = {
  message: UiTextTranscription
  isPlaying: boolean
  isDisabled: boolean
  hasAudio: boolean
  cursor: number
  translatedText?: string | null
  onPlayStart: (range: { from: number; to?: number }) => void
  onPlayStop: () => void
}

type PlayMessageProps = {
  isPlaying: boolean
  isDisabled: boolean
  onPlayStart: () => void
  onPlayStop: () => void
}

export const PlayMessage = ({
  isPlaying,
  onPlayStart,
  onPlayStop,
  isDisabled,
}: PlayMessageProps) => {
  return !isPlaying ? (
    <button
      className="btn bg-transparent icon-play"
      onClick={onPlayStart}
      disabled={isDisabled}
    >
      <FaPlay size={19}>Start</FaPlay>
    </button>
  ) : (
    <button
      className="btn bg-transparentb btn-icon icon-stop"
      onClick={onPlayStop}
    >
      <FaStop size={19}>Stop</FaStop>
    </button>
  )
}

export const TranscriptDisplayer = ({
  message: { msg, type, date, startTime, endTime, participant },
  translatedText = null,
  hasAudio,
  isPlaying,
  isDisabled,
  cursor,
  onPlayStart,
  onPlayStop,
}: TranscriptDisplayerProps) => {
  const isActive = cursor >= startTime! && cursor < endTime!

  return (
    <div
      className={`output-translation-text ${
        type === ActionType.UI ? "ui" : "speech"
      }`}
    >
      <div className="message-data">
        <div>{`${date}`}</div>
        <div className="text-primary">{`${
          participant?.displayName || __("UNKNOWN_PERSON")
        }`}</div>
      </div>
      <div className={`message-bubble ${isActive ? "active" : ""}`}>
        <div className="message">
          {msg}
          {translatedText && (
            <>
              <hr className="separation-line" />
              <div className="message-translated">{translatedText}</div>
            </>
          )}
        </div>

        <div className="controls">
          {hasAudio && (
            <PlayMessage
              isPlaying={isPlaying && cursor >= startTime! && cursor < endTime!}
              isDisabled={isDisabled}
              onPlayStart={() => onPlayStart({ from: startTime! })}
              onPlayStop={onPlayStop}
            />
          )}
        </div>
      </div>
    </div>
  )
}
