import { Code } from "components/Code/Code"
import { FaMicrophoneAlt } from "react-icons/fa"
import { TutorialItem } from "../../TutorialItem/TutorialItem"

// https://gist.github.com/matiasmm/c1992f2dd9222d765f8dd02c831e66ac
const URL_ONLINE_COMMAND =
  "https://gist.githubusercontent.com/matiasmm/c1992f2dd9222d765f8dd02c831e66ac/raw/setup-linux-virtual-devices.sh"

export const InitialHelpLinux = ({ prefixID = "" }: { prefixID: string }) => {
  return (
    <div>
      <TutorialItem
        id={`${prefixID}-1`}
        n={1}
        urlNext={`#${prefixID}-2`}
        title="Instala pavucontrol"
        dscTop={
          <>
            <div>
              En linux, podemos crear un dispositivo de audio virtual a través
              de Pavucontrol. Puedes instalarlo mediante alguno de los
              siguientes comandos según tu distribución:
              <p>
                <br />
                Ubuntu/Debian/Mint
                <Code>sudo apt-get install pavucontrol</Code>
              </p>
              <p>
                Fedora/Red Hat:
                <Code>sudo dnf install pavucontrol</Code>
              </p>
              <p>
                Arch Linux/Manjaro:
                <Code>sudo pacman -S pavucontrol</Code>
              </p>
            </div>
          </>
        }
      />
      <TutorialItem
        id={`${prefixID}-2`}
        n={2}
        urlNext={`#${prefixID}-3`}
        title="Asegúrate de que tus auriculares estén conectados y corre este comando"
        dscTop={
          <>
            <div>
              Es importante que tus auriculares estén conectados antes de correr
              el siguiente comando. Este comando, creará un dispositivo de
              salida múltiple, que nos permitirá enviar audio a MyInterpreter y
              a tus auriculares simultáneamente.
              <Code>{`bash <(curl -s ${URL_ONLINE_COMMAND})`}</Code>
            </div>
          </>
        }
      />

      <TutorialItem
        id={`${prefixID}-3`}
        n={3}
        imageUrl="/images/linux/app-setup.png"
        imageClass="img-50"
        title="Todo listo !!!"
        dscTop={
          <>
            <p>
              Crea una nueva transcripción entrando a:
              <a href="https://app.myinterpreter.co/recorder">
                https://app.myinterpreter.co/recorder
              </a>
            </p>
            <p>
              Asegurate que <code>Virtual-Microphone</code> esté seleccionado
              como dispositivo de entrada.
            </p>
            <p>
              Y haz click en{" "}
              <FaMicrophoneAlt
                size={20}
                className="start-button-ref"
              ></FaMicrophoneAlt>{" "}
              para comenzar la grabacion:
            </p>
          </>
        }
        imageUrl2="/images/linux/zoom.png"
        imageClass2="img-100"
        dscBottom={
          <>
            Y en la aplicación desde donde quieres grabar el sonido (Zoom,
            Microft Teams, etc.), selecciona el dispositivo "
            <code>
              Simultaneous output to Mix-for-Virtual-Microphone,TU DISPOSITIVO
            </code>
            .
          </>
        }
        dscBottom2="Esperamos que disfrutes de la experiencia."
      />
    </div>
  )
}
