import { createReducer } from "@reduxjs/toolkit"
import { EXT_STATE } from "../actions/extension"

const initialState = {
  recordingState: "",
  isAuth: false,
}

export const audioReducer = createReducer(initialState, (builder) => {
  builder.addCase(EXT_STATE, (state, { payload }) => {
    state.recordingState = payload.recordingState
    state.isAuth = payload.isAuth
  })
})

export default audioReducer
