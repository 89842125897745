import { LANGUAGE_CODE } from "config"
import { EnI18N } from "./en"
import { EsI18N } from "./es"

const LANGUAGE_MAP = {
  es: EsI18N,
  en: EnI18N,
}

export const __ = (
  KEY: Exclude<keyof typeof EnI18N, "prototype">,
  ...rest: any
) => {
  const LanguageClass = LANGUAGE_MAP[LANGUAGE_CODE]

  return String(LanguageClass[KEY])
}

export const format = function (text: string, ...args: string[]) {
  return text.replace(/{(\d+)}/g, function (match, pos) {
    return typeof args[pos] !== "undefined" ? args[pos] : match
  })
}
