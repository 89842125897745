import { BaseApp } from "components/App/BaseApp/BaseApp"
import React from "react"

import "./privacyPage.scss"

export const PrivacyPage: React.FC = () => {
  return (
    <BaseApp className="privacy-page">
      <div className="container">
        <div>
          <header>
            <h1>Privacy Policy</h1>
          </header>
          <section>
            <h2>Personal information</h2>
            <p>
              The types of personal information or personal data we may collect
              about you include:
            </p>
            <ul>
              <li>Your name</li>
              <li>Your contact details, including email address</li>
              <li>
                Any other personal information requested by us and/or provided
                by you or a third party.
              </li>
            </ul>
            <p>
              We may collect these types of personal information directly from
              you or from third parties.
            </p>
          </section>
          <section>
            <h2>Collection and use of personal information</h2>
            <p>
              We may collect, hold, use, and disclose personal information for
              the following purposes:
            </p>
            <ul>
              <li>
                To enable you to access and use our services or the third-party
                services, including storing it.
              </li>
              <li>To contact and communicate with you.</li>
              <li>For internal record-keeping and administrative purposes.</li>
              <li>
                To comply with our legal obligations and resolve any disputes
                that we may have.
              </li>
            </ul>
          </section>
          <section>
            <h2>Disclosure of personal information to third parties</h2>
            <p>We may disclose personal information to:</p>
            <ul>
              <li>
                Third-party service providers for the purpose of enabling them
                to provide their services, including (without limitation) IT
                service providers, data storage, web-hosting and server
                providers, maintenance or problem-solving providers, marketing
                providers, professional advisors, and payment systems operators.
              </li>
              <li>
                Courts, tribunals, regulatory authorities, and law enforcement
                officers, as required by law, in connection with any actual or
                prospective legal proceedings, or in order to establish,
                exercise, or defend our legal rights.
              </li>
            </ul>
          </section>
          <section>
            <h2>Your rights and controlling your personal information</h2>
            <p>
              If you believe that we have breached the Australian Privacy
              Principles or an article of the GDPR and wish to make a complaint,
              please contact us using the details below and provide us with full
              details of the alleged breach. We will promptly investigate your
              complaint and respond to you, in writing, setting out the outcome
              of our investigation and the steps we will take to deal with your
              complaint.
            </p>
          </section>
          <section>
            <h2>Transcriptions captured by MyInterpreter</h2>
            <p>
              Any transcriptions created with MyInterpreter belong to you. You
              can control where your transcriptions are stored using settings
              provided in the MyInterpreter web application. When you choose to
              store your recordings on a third-party service, they will be
              transferred directly to the respective remote service. Please
              consult the privacy policy of the applicable third-party service
              provider to see how it handles your files.
            </p>
          </section>
          <section>
            <h2>Security</h2>
            <p>
              We have strict security measures in place to protect your
              information and ensure that it is not lost, damaged, or accessed
              by anyone who should not see it.
            </p>
          </section>
          <section>
            <h2>Usage Data</h2>
            <p>
              We automatically collect information on how the MyInterpreter
              service is accessed and used. This Usage Data may include
              information such as your computer's Internet Protocol address
              (e.g. IP address), browser type, browser version, the pages of our
              Service that you visit, the time and date of your visit, the time
              spent on those pages, unique device identifiers and other
              diagnostic data.
            </p>
          </section>
          <section>
            <h2>Use of data</h2>
            <p>
              We may use the data collected from you for various purposes,
              including but not limited to:
            </p>
            <ul>
              <li>Improving and enhancing our services</li>
              <li>Analyzing usage patterns and trends</li>
              <li>Personalizing user experiences</li>
              <li>Providing customer support</li>
              <li>Conducting research and development</li>
              <li>Complying with legal obligations</li>
            </ul>
          </section>
          <section>
            <h2>Data Retention</h2>
            <p>
              As a matter of practice, we do not retain the audio data captured
              during the transcription process within our servers. At no point
              is the audio stored on our servers. Instead, it is promptly
              transmitted to a designated third-party service for transcription
              purposes. We recommend consulting the privacy policy of the
              designated third-party service provider, such as AssemblyAI
              (https://www.assemblyai.com/legal/privacy-policy), to gain insight
              into their procedures regarding data handling and retention.
              Furthermore, it is important to note that while the transcribed
              text is transmitted to our servers for translation purposes, we do
              not retain this data beyond its immediate use. Any transcribed
              text is promptly processed for translation and is not stored on
              our servers thereafter.
            </p>
          </section>
          <section>
            <h2>Links To Other Sites</h2>
            <p>
              Our Service may contain links to other sites that are not operated
              by us. If you click on a third party link, you will be directed to
              that third party's site. We recommend you review the Privacy
              Policy of every site you visit. We have no control over and assume
              no responsibility for the content, privacy policies or practices
              of any third party sites or services.
            </p>
          </section>
          <section>
            <h2>Service Providers</h2>
            <p>
              We may employ third party companies and individuals to perform
              functions on our behalf, including to facilitate our Service
              ("Service Providers"), to provide the Service on our behalf, to
              perform Service-related services or to assist us in analyzing how
              our Service is used. These third parties have access to your
              Personal Data only to perform these tasks on our behalf and are
              obligated not to disclose or use it for any other purpose.
            </p>
          </section>
          <section>
            <h2>Cookies and web beacons</h2>
            <p>
              We may use cookies on our Site from time to time. Cookies are text
              files placed in your computer's browser to store your preferences.
              Cookies, by themselves, do not tell us your email address or other
              personally identifiable information. However, they do allow third
              parties, such as Google and Facebook, to cause our advertisements
              to appear on your social media and online media feeds as part of
              our retargeting campaigns. If and when you choose to provide our
              Site with personal information, this information may be linked to
              the data stored in the cookie.
            </p>
            <p>
              We may use web beacons on our Site from time to time. Web beacons
              (also known as Clear GIFs) are small pieces of code placed on a
              web page to monitor the visitor’s behaviour and collect data about
              the visitor’s viewing of a web page. For example, web beacons can
              be used to count the users who visit a web page or to deliver a
              cookie to the browser of a visitor viewing that page. We may use
              Google Analytics to collect and process data. To find out how
              Google uses data when you use third party websites or
              applications, please see{" "}
              <a href="https://policies.google.com/privacy">
                Google's Privacy Policy
              </a>{" "}
              or any other URL Google may use from time to time.
            </p>
          </section>
          <section>
            <h2>Contact</h2>
            <p>
              For any inquiries or concerns regarding our privacy policy, please
              feel free to contact us at actionatics.srl@email.com.
            </p>
          </section>
        </div>
      </div>
    </BaseApp>
  )
}
