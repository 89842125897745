import {
  ActionType,
  UiTextTranscription,
} from "@actionatics/rtstt-shared/build/dataTypes/UiText"
import { ApiClient } from "api/ApiClient"
import { Dispatch } from "redux"
import { RootState } from "state/reducers"
import { debounceTime } from "utils/debouncers"
import { TRACK_USAGE } from "../actions"

export const trackListeners = (dispatch: Dispatch) => ({
  [TRACK_USAGE.type]: debounceTime(
    5000,
    5000,
    async (_: any, { ui, user }: RootState) => {
      let messagesCount = 0
      let tokensCount = 0
      let seconds = 0

      ui.events.map((e) => {
        if (e.type === ActionType.SPEECH) {
          const event = e as UiTextTranscription
          tokensCount += event.msg.split(" ").length
          messagesCount += 1
          seconds = event.endTime || 0
        }

        return null
      })

      if (messagesCount > 0) {
        const api = new ApiClient(user.token)
        await api.usage(ui.id, messagesCount, tokensCount, seconds)
      }
    }
  ),
})
