import { useCallback } from "react"
import { FaMicrophoneAlt, FaPlay, FaQuestion, FaStop } from "react-icons/fa"
import { useNavigate } from "react-router-dom"
import logo from "../../assets/logo-with-background-512x512.png"
import "./leftMenu.scss"

type LeftMenuProps = {
  isRecording: boolean
  isPlaying: boolean
  hideRecordingButtons?: boolean
  onRecordStart: () => void
  onRecordStop: () => void
  onPlayStart: () => void
  onPlayStop: () => void
}

export const LeftMenu = ({
  hideRecordingButtons = false,
  isRecording,
  isPlaying,
  onRecordStart,
  onRecordStop,
  onPlayStart,
  onPlayStop,
}: LeftMenuProps) => {
  const navigate = useNavigate()
  const handleHelpClick = useCallback(
    () => navigate("/help", { replace: true }),
    [navigate]
  )

  // if (!hideRecordingButtons) {
  //   return (
  //     <div className="d-flex align-self-start vh-100 flex-column m-1">
  //       <button className="mt-2 question-button" onClick={handleHelpClick}>
  //         <FaBackward size={19}>Back</FaBackward>
  //       </button>
  //       <img className="logo-img mt-auto mb-4 p-1" src={logo} alt="logo" />
  //     </div>
  //   )
  // }

  return (
    <div className="d-flex align-self-start vh-100 flex-column m-1 left-menu">
      {!isRecording ? (
        <button
          className="start-button mt-1 record-button"
          onClick={onRecordStart}
        >
          <FaMicrophoneAlt size={19} color="secondary">
            Record
          </FaMicrophoneAlt>
        </button>
      ) : (
        <button className="mt-2 stop-button" onClick={onRecordStop}>
          <FaStop size={19} color="primary">
            Stop
          </FaStop>
        </button>
      )}
      {!isPlaying ? (
        <button
          className="start-button mt-1 play-button"
          onClick={onPlayStart}
          disabled={isRecording}
        >
          <FaPlay size={19} color="secondary">
            Start
          </FaPlay>
        </button>
      ) : (
        <button className="mt-2 stop-button" onClick={onPlayStop}>
          <FaStop size={19} color="primary">
            Stop
          </FaStop>
        </button>
      )}
      <button className="mt-2 question-button" onClick={handleHelpClick}>
        <FaQuestion size={19}>How to integrate it with zoom</FaQuestion>
      </button>
      <img className="logo-img mt-auto mb-4 p-1" src={logo} alt="logo" />
    </div>
  )
}
