import {
  WS_CLIENT_TO_SERVER,
  WS_SERVER_TO_CLIENT,
} from "@actionatics/rtstt-shared/build/Communication/constants"
import { ActionType } from "@actionatics/rtstt-shared/build/dataTypes/UiText"
import { Dispatch } from "redux"
import { Socket } from "socket.io-client"
import { TRANSCRIPT_TEXT_ADD } from "state/actions"
import { UI_BOT_STATE } from "state/actions/ui"
import { wsJoin } from "./ws-consts"

export const wsOnConnect = (
  socket: Socket,
  dispatch: Dispatch,
  sessionId: string
) => {
  socket.emit(WS_CLIENT_TO_SERVER, wsJoin(sessionId))
  socket.on(WS_SERVER_TO_CLIENT, (event: { type: string; payload: any }) => {
    const currentDate = new Date()
    const formattedDate = currentDate.toTimeString().slice(0, 8)

    switch (event.type) {
      case TRANSCRIPT_TEXT_ADD.type:
        dispatch({
          type: TRANSCRIPT_TEXT_ADD.type,
          payload: {
            type: ActionType.SPEECH,
            date: formattedDate,
            startTime: Date.now(),
            endTime: Date.now(),
            ...(event.payload as object),
          },
        })
        break
      case "state":
        dispatch(UI_BOT_STATE(event.payload))
        break
      default:
        dispatch(event)
    }
  })
}
