import { createReducer } from "@reduxjs/toolkit"
import {
  CONFERENCE_GET_MEETING_DETAILS_SUCCESS,
  CONFERENCE_GET_ZOOM_SIGNATURE_SUCCESS,
} from "../actions/conference"

interface State {
  meetingDetails: string
  zoomSignature: string
  zoomSdkKey: string
}

const initialState: State = {
  meetingDetails: "",
  zoomSignature: "",
  zoomSdkKey: "",
}

export default createReducer(initialState, (builder) => {
  builder.addCase(
    CONFERENCE_GET_MEETING_DETAILS_SUCCESS,
    (state, { payload }) => {
      state.meetingDetails = payload.meetingDetails
    }
  )
  builder.addCase(
    CONFERENCE_GET_ZOOM_SIGNATURE_SUCCESS,
    (state, { payload }) => {
      state.zoomSignature = payload.signature
      state.zoomSdkKey = payload.sdkKey
    }
  )
})
