import { BoxOption } from "components/BoxOption/BoxOption"
import { TutorialItem } from "components/TutorialItem/TutorialItem"
import { useState } from "react"
import { Container } from "react-bootstrap"
import Nav from "react-bootstrap/Nav"
import Tab from "react-bootstrap/Tab"
import { FaHeadphones, FaMicrophoneAlt } from "react-icons/fa"
import { useDispatch } from "react-redux"
import { UI_SHOW_INSTRUCTIONS } from "state/actions"
import { getOS, OS_Linux, OS_Mac, OS_Windows } from "utils/os"
import { InitialHelpLinux } from "./InitialHelpLinux"
import { InitialHelpMAC } from "./InitialHelpMAC"
import { InitialHelpWindows } from "./InitialHelpWindows"

export const InstructionsByOS = () => {
  const os_default = getOS() || OS_Windows
  // const os_default = OS_Linux
  const prefixID = "tutorial-item"

  return (
    <Container>
      <Tab.Container id="left-tabs-example" defaultActiveKey={os_default}>
        <TutorialItem
          id="tutorial-item-init"
          urlNext={`#${prefixID}-1`}
          title={
            <>
              <FaHeadphones fontSize={50} />
              <span className="pl-6 ml-2">
                {"   "}&nbsp; Usas auriculares en tus videollamadas?
              </span>
            </>
          }
          dscTop={
            <div>
              My Interpreter por defecto solo puede capturar el audio de tu
              micrófono. Si deseas que capture el audio de una conversación de
              otra app como Zoom, o Microsoft Teams, debes configurarlo.
            </div>
          }
          dscBottom={
            <>
              <div>
                Sigue las instrucciones para hacerlo según tu sistema operativo:
              </div>

              <Nav variant="pills" className="mt-4">
                <Nav.Item>
                  <Nav.Link eventKey={OS_Windows}>{OS_Windows}</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey={OS_Mac}>{OS_Mac}</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey={OS_Linux}>{OS_Linux}</Nav.Link>
                </Nav.Item>
              </Nav>
            </>
          }
        />

        <Tab.Content>
          <Tab.Pane eventKey={OS_Windows}>
            <InitialHelpWindows prefixID={prefixID} />
          </Tab.Pane>
          <Tab.Pane eventKey={OS_Mac}>
            <InitialHelpMAC prefixID={prefixID} />
          </Tab.Pane>
          <Tab.Pane eventKey={OS_Linux}>
            <InitialHelpLinux prefixID={prefixID} />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </Container>
  )
}

export const InitialHelp = ({
  enableOnboardingButon,
}: {
  enableOnboardingButon: boolean
}) => {
  const dispatch = useDispatch()
  const [showHeadphonesInstructions, setSHowedHeadphonesInstructions] =
    useState(false)

  const onClickInstruction = () => {
    dispatch(UI_SHOW_INSTRUCTIONS(true))
  }

  if (showHeadphonesInstructions) {
    return <InstructionsByOS />
  }

  return (
    <Container>
      <div className="row">
        <div className="col-12 col-md-6">
          <BoxOption
            title={
              <>
                <FaMicrophoneAlt fontSize={50} />
                <span className="pl-6 ml-2">
                  {"   "}&nbsp; Graba tu propio audio
                </span>
              </>
            }
          >
            <div>
              <p>
                Es la opción más sencilla para probar la app. Sigue las
                instrucciones:
              </p>
              <ol>
                <li>
                  Selecciona el dispositivo de entrada de audio{" "}
                  {enableOnboardingButon && (
                    <button
                      className="btn btn-danger"
                      onClick={onClickInstruction}
                    >
                      Ubicarlo
                    </button>
                  )}
                </li>
                <li>
                  {" "}
                  Haz click en{" "}
                  <FaMicrophoneAlt
                    size={24}
                    className="start-button-ref"
                  ></FaMicrophoneAlt>{" "}
                  para comenzar a transcribir tu audio
                </li>
                <li>
                  Haz una prueba hablando <b>en inglés</b>. Es importante
                  recordar qué solo intentará interpretar audio en inglés, si
                  hablas en español es posible que no veas resultados.
                </li>
              </ol>
            </div>
          </BoxOption>
        </div>
        <div className="col-12 col-md-6">
          <BoxOption
            title={
              <>
                <FaHeadphones fontSize={50} />
                <span className="pl-6 ml-2">
                  {"   "}&nbsp; Usas auriculares en tus videollamadas?
                </span>
              </>
            }
            buttonLabel="Configurar para transcribir audio de otras aplicaciones"
            onClick={() => setSHowedHeadphonesInstructions(true)}
          >
            <div>
              <p>
                My Interpreter por defecto solo puede capturar el audio de tu
                micrófono. Si deseas que capture el audio de una conversación de
                otra app como Zoom, o Microsoft Teams, debes configurarlo.{" "}
              </p>
              <br />
              <p>
                Haz clic en el botón aquí debajo y te ayudaremos a configurarlo
              </p>
            </div>
          </BoxOption>
        </div>
      </div>
    </Container>
  )
}
