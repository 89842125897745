import { InputDeviceInfo } from "@actionatics/rtstt-shared/build/dataTypes/InputDeviceInfo"
import { UiTextBot, UiTextTranscription } from "@actionatics/rtstt-shared/build/dataTypes/UiText"
import { createAction } from "@reduxjs/toolkit"
import { UserData } from "../../types/userTypes"

type TranslatedText = {
  [id: string]: string
}

export const TRANSCRIPT_TEXT_ADD = createAction<UiTextTranscription>(
  "TRANSCRIPT_TEXT_ADD"
)
export const UI_TRANSLATED_TEXT_ADD = createAction<TranslatedText>(
  "UI_TRANSLATED_TEXT_ADD"
)

export const UI_TRANSLATED_TEXT_INIT = createAction<{
  id: string
  textToTranslate: string
  isFinal: boolean
}>("UI_TRANSLATED_TEXT_INIT")

export const DEVICES_INIT = createAction("DEVICES_INIT")
export const DEVICES_SUCCESS =
  createAction<InputDeviceInfo[]>("DEVICES_SUCCESS")
export const DEVICES_ERROR = createAction("DEVICES_ERROR")
export const DEVICES_SELECT = createAction<number>("DEVICES_SELECT")

export const AUDIO_RECORD_START =
  createAction<InputDeviceInfo>("AUDIO_RECORD_START")
export const AUDIO_RECORD_END = createAction("AUDIO_RECORD_END")
export const AUDIO_PLAY_START = createAction<
  | {
      from: number
      to?: number
    }
  | undefined
>("AUDIO_PLAY_START")
export const AUDIO_PLAY_STOP = createAction("AUDIO_PLAY_STOP")
export const AUDIO_UPDATE_CURSOR = createAction<number>("AUDIO_UPDATE_CURSOR")

export const UI_ADD_CUSTOM_EVENT = createAction<{
  message: string
  id?: string
}>("UI_ADD_CUSTOM_EVENT")

export const UI_ADD_BOT_EVENT = createAction<UiTextBot>("UI_ADD_BOT_EVENT")

export const USER_SET_TOKEN = createAction<string>("USER_SET_TOKEN")
export const USER_SET_DATA = createAction<UserData | null>("USER_SET_DATA")

export const TRACK_USAGE = createAction("TRACK_USAGE")

export const UI_SHOW_INSTRUCTIONS = createAction<boolean>(
  "UI_SHOW_INSTRUCTIONS"
)
