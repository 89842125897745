import {
  BotState,
  StreamState,
} from "@actionatics/rtstt-shared/build/dataTypes/BotState"
import { __ } from "i18n"

type BotStateDisplayProps = { state: BotState; stream: StreamState }

const GREEN = "rgb(25, 135, 84)"
const YELLOW = "yellow"
const BLACK = "gray"
const RED = "red"

const MAPPING = {
  [BotState.INACTIVE]: { color: BLACK, text: __(BotState.INACTIVE) },
  [BotState.CLOSED]: { color: BLACK, text: __(BotState.CLOSED) },
  [BotState.JOINING]: { color: YELLOW, text: __(BotState.JOINING) },
  [BotState.CONNECTED]: { color: GREEN, text: __(BotState.CONNECTED) },
  [BotState.JOINING_ERROR]: { color: RED, text: __(BotState.JOINING_ERROR) },

  [StreamState.NOT_STARTED]: {
    color: YELLOW,
    text: __(StreamState.NOT_STARTED),
  },
  [StreamState.WAITING_FOR_AUDIO]: {
    color: GREEN,
    text: __(StreamState.WAITING_FOR_AUDIO),
  },
  [StreamState.STREAMING]: { color: GREEN, text: __(StreamState.STREAMING) },
  [StreamState.ERROR]: { color: RED, text: __(StreamState.ERROR) },
}

export const BotStateDisplay = ({ state, stream }: BotStateDisplayProps) => {
  const displayedState =
    state === BotState.CONNECTED ? MAPPING[stream] : MAPPING[state]
  return (
    <>
      <span className="badge" style={{ backgroundColor: displayedState.color }}>
        &nbsp;
      </span>{" "}
      {displayedState.text}
    </>
  )
}
