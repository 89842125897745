import { __ } from "i18n"

type BotTimeDisplayProps = { time: number }

function convertSeconds(seconds: number) {
  var hours = Math.floor(seconds / 3600)
  var minutes = Math.floor((seconds % 3600) / 60)
  var remainingSeconds = Math.floor(seconds % 60)

  var result = ""
  if (hours > 0) {
    result += hours + ` ${__("HOURS")}, `
  }
  if (minutes > 0) {
    result += minutes + ` ${__("MINUTES")}, `
  }
  if (remainingSeconds > 0 || result === "") {
    result += remainingSeconds + ` ${__("SECONDS")}`
  }

  return result
}

export const BotTimeDisplay = ({ time }: BotTimeDisplayProps) => {
  return <>{convertSeconds(time)}</>
}
