import { useAuth0 } from "@auth0/auth0-react"
import { __ } from "i18n"
import { Link } from "react-router-dom"

type NotLoggedInProps = { loginWithRedirect: () => any }

export const NotLoggedIn = ({ loginWithRedirect }: NotLoggedInProps) => {
  return (
    <button
      className="btn btn-primary btn-lg"
      onClick={() => loginWithRedirect()}
    >
      Registrarse / Login
    </button>
  )
}

export const LoggedIn = ({
  logout,
  username,
}: {
  logout: () => any
  username: string
}) => {
  return (
    <div className="d-flex">
      <span className="btn-link btn text-secondary text-decoration-none btn-lg">
        Hi {username}
      </span>
      <button
        className="btn  btn-outline-primary btn-lg py-0"
        onClick={() => logout()}
      >
        Logout
      </button>
    </div>
  )
}

export const Navbar = () => {
  const { isAuthenticated, loginWithRedirect, logout, user } = useAuth0()

  return (
    <nav
      id="navbar-spa"
      className="navbar navbar-expand-lg navbar-light bg-light"
    >
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarNav">
        <ul className="navbar-nav d-flex align-items-center">
          <li className="nav-item">
            <img src="/logo.svg" height={80} alt="MyInterpreter" />
          </li>
          <li className="nav-item">
            <Link className="nav-link text-primary" to={"/setup-meeting"}>
              {__("SETUP_NEW_MEETING")}
            </Link>
          </li>
          <li className="nav-item"></li>
        </ul>
      </div>
      <div className="ml-auto ">
        <div className="me-4">
          {isAuthenticated ? (
            <LoggedIn
              username={user?.given_name || ""}
              logout={() => logout({ returnTo: window.location.href })}
            />
          ) : (
            <NotLoggedIn loginWithRedirect={loginWithRedirect} />
          )}
        </div>
      </div>
    </nav>
  )
}
