import { listDevices } from "@actionatics/rtstt-shared/build/AudioManager"
import { Dispatch } from "redux"
import { DEVICES_INIT, DEVICES_SUCCESS } from "../actions"
import { RootState } from "../store"

export const devicesListeners = (dispatch: Dispatch) => ({
  [DEVICES_INIT.type]: async (_: any, state: RootState) => {
    const devices = await listDevices("audioinput", true, false)
    dispatch(DEVICES_SUCCESS(devices))
  },
})
