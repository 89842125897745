import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"

const tolerance = 10

const checkIsAtTheBottom = (elm: HTMLElement): boolean => {
  if (elm.scrollTop >= elm.scrollHeight - elm.offsetHeight - tolerance) {
    return true
  }
  return false
}

const setAtBottom = (elm: HTMLElement) => {
  elm.scrollTop = elm.scrollHeight - elm.offsetHeight
}

export const useStickyScroll = (
  dependencies: any[]
): [MutableRefObject<HTMLDivElement | null>, boolean] => {
  const scrollableDivRef = useRef<HTMLDivElement | null>(null)
  const [isListening, setIsListening] = useState(false)
  // for performance resons
  const isScrollingRef = useRef<boolean>(false)
  const [isAtTheBottom, setIsAtTheBottom] = useState(true)

  const onScroll = useCallback(() => {
    if (scrollableDivRef.current) {
      if (checkIsAtTheBottom(scrollableDivRef.current)) {
        isScrollingRef.current = false
        setIsAtTheBottom(true)
      } else {
        isScrollingRef.current = true
        setIsAtTheBottom(false)
      }
    }
  }, [])

  useEffect(() => {
    if (scrollableDivRef.current) {
      if (!isListening) {
        scrollableDivRef.current.addEventListener("scroll", onScroll)
      }
    }

    return () => {
      setIsListening(false)
      // eslint-disable-next-line react-hooks/exhaustive-deps
      scrollableDivRef.current?.removeEventListener("scroll", onScroll)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencies, isListening, scrollableDivRef.current])

  useEffect(() => {
    if (scrollableDivRef.current) {
      if (isScrollingRef.current === false) {
        setAtBottom(scrollableDivRef.current)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencies, scrollableDivRef.current])

  return [scrollableDivRef, isAtTheBottom]
}
