import { createAction } from "@reduxjs/toolkit"
import { ZoomGetSignatureRequest } from "api/ResponsesTypes"

export const CONFERENCE_GET_MEETING_DETAILS_INIT = createAction<string>(
  "CONFERENCE_GET_MEETING_DETAILS_INIT"
)

export const CONFERENCE_GET_MEETING_DETAILS_SUCCESS = createAction<{
  meetingDetails: string
}>("CONFERENCE_GET_MEETING_DETAILS_SUCCESS")

export const CONFERENCE_GET_MEETING_DETAILS_ERROR = createAction<any>(
  "CONFERENCE_GET_MEETING_DETAILS_ERROR"
)

// Zoom
export const CONFERENCE_GET_ZOOM_SIGNATURE_INIT =
  createAction<ZoomGetSignatureRequest>("CONFERENCE_GET_ZOOM_SIGNATURE_INIT")
export const CONFERENCE_GET_ZOOM_SIGNATURE_SUCCESS = createAction<{
  signature: string
  sdkKey: string
}>("CONFERENCE_GET_ZOOM_SIGNATURE_SUCCESS")
export const CONFERENCE_GET_ZOOM_SIGNATURE_ERROR = createAction<any>(
  "CONFERENCE_GET_ZOOM_SIGNATURE_ERROR"
)
