export const OS_Mac = "Mac OS"
export const OS_iOS = "iOS"
export const OS_Windows = "Windows"
export const OS_Android = "Android"
export const OS_Linux = "Linux"

export function getOS() {
  var userAgent = window.navigator.userAgent,
    platform = window.navigator.platform,
    macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
    windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
    iosPlatforms = ["iPhone", "iPad", "iPod"],
    os = null

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = OS_Mac
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = OS_iOS
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = OS_Windows
  } else if (/Android/.test(userAgent)) {
    os = OS_Android
  } else if (!os && /Linux/.test(platform)) {
    os = OS_Linux
  }

  return os
}
