import {
  BotState,
  StreamState,
} from "@actionatics/rtstt-shared/build/dataTypes/BotState.js"

import { createAction } from "@reduxjs/toolkit"

export const UI_SESSION_CREATE_INIT = createAction<{
  meetingDetails: string
  isCustomClient: boolean
}>("UI_SESSION_CREATE_INIT")

export const UI_SESSION_CREATE_SUCCESS = createAction<string>(
  "UI_SESSION_CREATE_SUCCESS"
)
export const UI_SESSION_CREATE_ERROR = createAction("SESSION_CREATE_ERROR")

export const UI_BOT_STATE = createAction<{
  stream: StreamState
  bot: BotState
  time: number
}>("UI_BOT_STATE")
