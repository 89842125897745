import { StreamState } from "@actionatics/rtstt-shared/build/dataTypes/BotState"
import { BaseApp } from "components/App/BaseApp/BaseApp"
import { AudioMessagesContainer } from "components/AudioMessagesContainer/AudioMessagesContainer"
import { BotStateDisplay } from "components/BotStateDisplay/BotStateDisplay"
import { BotTimeDisplay } from "components/BotTimeDisplay/BotTimeDisplay"
import { Code } from "components/Code/Code"
import { MediaPlayer } from "components/MediaPlayer/MediaPlayer"
import { useEffectOnce } from "hooks/useEffectOnce"
import { useRealTimeVideo } from "hooks/useRealTimeVideo"
import { useStickyScroll } from "hooks/useStickyScroll"
import { __ } from "i18n"
import { useCallback } from "react"
import { Button } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { AUDIO_UPDATE_CURSOR } from "state/actions"
import { CONFERENCE_GET_MEETING_DETAILS_INIT } from "state/actions/conference"
import { WS_JOIN } from "state/actions/ws"
import { RootState } from "state/store"
import { fakeEvents } from "utils/fakeEvents"
import "./transcriptPage.scss"

const FAKE_EVENTS = false

export const TranscriptPage = () => {
  const navigator = useNavigate()
  const { sessionId } = useParams()
  const dispatch = useDispatch()

  useEffectOnce(() => {
    if (FAKE_EVENTS) {
      fakeEvents(dispatch)
    } else {
      dispatch(WS_JOIN({ sessionId: sessionId || "" }))
    }

    dispatch(CONFERENCE_GET_MEETING_DETAILS_INIT(sessionId || ""))
  })

  const handleLeave = useCallback(() => {
    navigator("/setup-meeting")
  }, [navigator])
  const events = useSelector((state: RootState) => state.ui.events)
  const stateBot = useSelector((state: RootState) => state.ui.stateBot)
  const stateStream = useSelector((state: RootState) => state.ui.stateStream)
  const timeElapsed = useSelector((state: RootState) => state.ui.time)
  const cursor = useSelector((state: RootState) => state.audio.cursor)
  const translatedText = useSelector(
    (state: RootState) => state.ui.translatedTexts
  )
  const videoUrl = useRealTimeVideo(sessionId || "", stateBot, stateStream)
  const meetingDetails = useSelector(
    (state: RootState) => state.conference.meetingDetails
  )

  const [scrollableDivRef, isScrollAtBottom] = useStickyScroll([events])
  const goToBottomScroll = useCallback(() => {
    if (scrollableDivRef.current) {
      const elm = scrollableDivRef.current
      elm.scrollTop = elm.scrollHeight - elm.offsetHeight
    }
  }, [scrollableDivRef])

  const onMediaPlayerChangeCursor = useCallback(
    (time: number) => {
      dispatch(AUDIO_UPDATE_CURSOR(time))
    },
    [dispatch]
  )

  return (
    <BaseApp className="transcript-page">
      <div className="container">
        <div className="grid-top">
          <div className="grid-top-state">
            <BotStateDisplay state={stateBot} stream={stateStream} />
            <br />
            <BotTimeDisplay time={timeElapsed} />
          </div>
          <div className="grid-top-form">
            {meetingDetails && (
              <>
                {__("TRANSCRIPT_FROM")}
                <Code theme="link-small">
                  <>
                    <div className="small">{meetingDetails}</div>
                  </>
                </Code>
              </>
            )}
          </div>
          <div className="grid-top-buttons">
            <Button className="btn btn-primary btn-lg" onClick={handleLeave}>
              {__("Leave")}
            </Button>
          </div>
          {videoUrl && (
            <div className="grid-top-video">
              <MediaPlayer
                src={videoUrl}
                onChangeCursor={onMediaPlayerChangeCursor}
              />
            </div>
          )}
        </div>
        <div className="grid-messages" ref={scrollableDivRef}>
          {events.length === 0 ? null : (
            <AudioMessagesContainer
              events={events}
              translatedText={translatedText}
              onPlayStart={() => null}
              onPlayStop={() => null}
              isRecording={stateStream === StreamState.STREAMING}
              isPlaying={true}
              cursor={cursor}
              isScrollAtBottom={isScrollAtBottom}
              goToBottomScroll={goToBottomScroll}
            />
          )}
        </div>
      </div>
    </BaseApp>
  )
}
