import { UiTextBot } from "@actionatics/rtstt-shared/build/dataTypes/UiText"
import { __ } from "i18n"
import { FaPlay, FaStop } from "react-icons/fa"
import "./BotMessageBoxStyles.scss"

type BotMessageBoxProps = {
  message: UiTextBot
}

type PlayMessageProps = {
  isPlaying: boolean
  isDisabled: boolean
  onPlayStart: () => void
  onPlayStop: () => void
}

export const PlayMessage = ({
  isPlaying,
  onPlayStart,
  onPlayStop,
  isDisabled,
}: PlayMessageProps) => {
  return !isPlaying ? (
    <button
      className="btn bg-transparent icon-play"
      onClick={onPlayStart}
      disabled={isDisabled}
    >
      <FaPlay size={19}>Start</FaPlay>
    </button>
  ) : (
    <button
      className="btn bg-transparentb btn-icon icon-stop"
      onClick={onPlayStop}
    >
      <FaStop size={19}>Stop</FaStop>
    </button>
  )
}

export const BotMessageBox = ({
  message: { msg, type, date },
}: BotMessageBoxProps) => {
  return (
    <div className="bot-message speech">
      <div className="message-data">
        <div>{`${date}`}</div>
        <div className="text-success">{__("BOT_NAME")}</div>
      </div>
      <div className="message-bubble">
        <div className="message">
          <pre>{msg}</pre>
        </div>
      </div>
    </div>
  )
}
