import { combineReducers } from "redux"

import audioReducer from "./audio"
import conferenceReducer from "./conference"
import devicesReducer from "./devices"
import extensionReducer from "./extension"
import uiReducer from "./ui"
import userReducer from "./user"

const reducers = combineReducers({
  extension: extensionReducer,
  user: userReducer,
  ui: uiReducer,
  devices: devicesReducer,
  audio: audioReducer,
  conference: conferenceReducer,
})

export default reducers
export type RootState = ReturnType<typeof reducers>
