import { useEffect, useRef } from "react"

export const useEffectOnce = (fn: Function) => {
  const ref = useRef(0)
  useEffect(() => {
    if (ref.current === 0) {
      ref.current = 1
      fn()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
